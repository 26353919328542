import React from "react";
import { Row, Image, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import tensegity from "../images/collaborazioni/tensegity-logo.webp";
import carioni from "../images/collaborazioni/carioni-logo.svg";
import naturopatiaBeth from "../images/collaborazioni/naturopatiaBeth.svg";
import cromomed from "../images/collaborazioni/cromomed.svg";
import zailo from "../images/collaborazioni/zailo.svg";
function Collaborazioni() {
  const list = [
    {
      key: 0,
      link: "https://www.tensegritypilates.it/",
      img: tensegity,
      name: "Tensegrity Pilates",
    },
    {
      key: 1,
      link: "http://www.fisioterapiacarioni.it/",
      img: carioni,
      name: "Fisioterapia Carioni",
    },
    /*{
      key: 2,
      link: "https://bethnaturopatia.it/",
      img: naturopatiaBeth,
      name: "Scuola di Naturopatia Beth",
    },
    {
      key: 3,
      link: "https://cromomed.com",
      img: cromomed,
      name: "Cromomed",
    },
    {
      key: 4,
      link: "https://zailo.it",
      img: zailo,
      name: "Zailo",
    },*/
  ];
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Amici - Studio Olistico Panta Rei</title>
        <meta
          name="description"
          content="scopri i nostri fantastici amici fra cui il centro di Fisioterapia Carioni e lo studio Tensegrity Pilates"
        />
        <meta
          name="keywords"
          content="Panta Rei, Centro di Fisioterapia Carioni, Tensegrity Pilates, Medicina Tradizionale Cinese, Massaggi, Cromopuntura, Coppettazione Cinese, Auricoloterapia, Moxibustione, Gua Sha Viso, Aroma Massage, Massaggio Bioenergetico, Thai Massage, Terapie Olistiche, Studio Olistico, Mtc, Kinesiologia, Coppettazione, moxa, Taping, Riflessologia e Dien Chan, Access Bars, Chakra, Fiori Bach e Australiani, Meditazione, Cassano d'Adda, Martesana, Milano, Cassano"
        />
        <meta property="og:title" content="Amici - Studio Olistico Panta Rei" />
      </Helmet>
      <Row className="mx-2 justify-content-center text-center mb-5">
        <h1>Amici</h1>
        {list.map((item) => (
          <Col key={item.key} xs={6} md={3} lg={2} className="my-2 ">
            <a href={item.link} target="_blank" rel="noreferrer noopener">
              <div className="scale-hover h-100 justify-content-center align-items-center d-flex flex-wrap ">
                <div className="m-3 w-100">
                  <Image
                    src={item.img}
                    alt={item.name}
                    fluid
                    className="w-100"
                  />
                </div>
              </div>
            </a>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default Collaborazioni;
