import React from "react";
import CategoryComponent from "../../components/CategoryComponent";
import Data from "../../Data/Categories/Massaggi";
import { Helmet } from "react-helmet-async";
function Massaggi({ footer = true }) {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Massaggi - Studio Olistico Panta Rei</title>
        <meta
          name="description"
          content="Esplora la varietà di massaggi offerti dallo Studio Olistico Panta Rei. Dai trattamenti con oli essenziali bio e Acque del Benessere alle tecniche bioenergetiche, cristalloterapia, massaggio vietnamita dei meridiani, massaggi energetici dei chakra, massaggi svedesi, dry brushing con legnoterapia e bamboo massage, thai massage a terra, fino al rituale cocoon al burro di karité. Ogni massaggio è progettato per favorire il benessere fisico, mentale e spirituale, offrendo un momento di relax profondo e rigenerante."
        />
        <meta
          name="keywords"
          content="Massaggi, Oli Essenziali, Acque del Benessere, Bioenergetico, Cristalloterapia, Massaggio Vietnamita, Chakra, Massaggio Svedese, Dry Brushing, Legnoterapia, Bamboo Massage, Thai Massage, Rituale Cocoon, Burro di Karité, Studio Olistico, Panta Rei, Mtc, Kinesiologia, Coppettazione, moxa, Moxibustione, Taping, Riflessologia e Dien Chan, Access Bars, Fiori Bach e Australiani, Meditazione, Medicina Tradizionale Cinese, Cassano d'Adda, Martesana, Milano,  Terapie Olistiche, Cassano"
        />
        <meta
          property="og:title"
          content="Massaggi - Studio Olistico Panta Rei"
        />
        <meta
          property="og:description"
          content="Vieni a scoprire la varietà di Massaggi Energetico-Vibrazionali presso lo Studio Olistico Panta Rei a Cassano D'Adda. Dalle proprietà energetiche degli oli essenziali all'armonizzazione con cristalli e acque del benessere, scopri trattamenti unici come Massaggio Bioenergetico, Cristalloterapia, Massaggio Vietnamita dei Meridiani, Massaggio Energetico dei Chakra, Massaggio Svedese, Dry Brushing, Thai Massage e Rituale Cocoon al Burro di Karité."
          data-rh="true"
        />
      </Helmet>
      <CategoryComponent data={Data} footer={footer} />
    </>
  );
}

export default Massaggi;
