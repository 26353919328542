import imageTest from "../images/template/template-square.png";

class Category {
  constructor(
    name,
    treatments = [],
    link = "",
    icon = imageTest,
    description = "",
    image = imageTest
  ) {
    this.name = name;
    this.treatments = treatments;
    this.link = link;
    this.icon = icon;
    this.image = image;
    this.description = description;
  }

  addElement(element) {
    this.treatments.push(element);
  }
}

export default Category;
