import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CookieConsent, {
  Cookies,
  resetCookieConsentValue,
} from "react-cookie-consent";
import favicon from "./images/loghi/logo-plain-square.png";
import faviconDark from "./images/loghi/logo-plain-white-square.png";

import ScrollToTop from "./components/ScrollToTop";

import CustomNavbar from "./components/Navbar";
import Footer from "./components/Footer";

import Homepage from "./pages/Home/Homepage";
import ChiSono from "./pages/ChiSono";
import Trattamenti from "./pages/Treatments/Trattamenti";
import Studio from "./pages/Studio";
import Collaborazioni from "./pages/Collaborazioni";
import Contatti from "./pages/Contatti";
import NoMatch from "./pages/404";
import Privacy from "./pages/Privacy";

import MedicinaTradizionaleCinese from "./pages/Treatments/MedicinaTradizionaleCinese";
import Massaggi from "./pages/Treatments/Massaggi";
import TecnicheDiRilassamento from "./pages/Treatments/TecnicheDiRilassamento";
import Riflessologia from "./pages/Treatments/Riflessologia";
import FitoterapiaEtc from "./pages/Treatments/FitoterapiaEtc";
import TapingEnergetico from "./pages/Treatments/TapingEnergetico";
import TerapieTradizionali from "./pages/Treatments/TerapieTradizionali";

import "./App.css";
import ContactsCircle from "./components/ContactsCircle";
import PopupModal from "./components/PopupModal";

function App() {
  const getFaviconPath = (isDarkMode = false) => {
    return isDarkMode ? faviconDark : favicon;
  };
  const [faviconHref, setFaviconHref] = useState(favicon);
  useEffect(() => {
    // Get current color scheme.
    const matcher = window.matchMedia("(prefers-color-scheme: dark)");
    // Set favicon initially.
    setFaviconHref(getFaviconPath(matcher.matches));
    // Change favicon if the color scheme changes.
    matcher.onchange = () => setFaviconHref(getFaviconPath(matcher.matches));
  }, [faviconHref]);
  const [dark, setDark] = useState(false);
  const changeTheme = () => {
    setDark(!dark);
  };
  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <title>Olistico Panta Rei</title>
        <link rel="icon" href={faviconHref} />
        <meta property="og:title" content="Studio olistico Panta Rei" />
        <meta
          property="og:description"
          content="Studio olistico Panta Rei di Nicole Cristine Rainer, Via vaprio 60, 20062 Cassano D'Adda (MI)"
          data-rh="true"
        />
        <meta property="og:image" content={favicon} />
      </Helmet>
      <BrowserRouter forceRefresh>
        <ScrollToTop />
        <div
          className={"d-flex p-0 m-0 flex-column " + (dark ? "yin" : "yang")}
        >
          <CustomNavbar dark={dark} changeTheme={changeTheme} />
          <div className="mx-0 px-0 main-content">
            <Container className="">
              <Routes>
                <Route path="/" element={<Homepage dark={dark} />} />
                <Route path="/chi-sono" element={<ChiSono />} />
                <Route
                  path="/trattamenti/medicina-tradizionale-cinese"
                  element={<MedicinaTradizionaleCinese />}
                />
                <Route path="/trattamenti/massaggi" element={<Massaggi />} />
                <Route
                  path="/trattamenti/tecniche-di-rilassamento"
                  element={<TecnicheDiRilassamento />}
                />
                <Route
                  path="/trattamenti/riflessologia"
                  element={<Riflessologia />}
                />
                <Route
                  path="/trattamenti/fitoterapia-e-aromaterapia"
                  element={<FitoterapiaEtc />}
                />
                <Route
                  path="/trattamenti/taping-energetico"
                  element={<TapingEnergetico />}
                />
                <Route
                  path="/trattamenti/terapie-tradizionali"
                  element={<TerapieTradizionali />}
                />
                <Route path="/trattamenti" element={<Trattamenti />} />
                <Route path="/studio" element={<Studio />} />
                <Route path="/collaborazioni" element={<Collaborazioni />} />
                <Route path="/contatti" element={<Contatti />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="*" element={<NoMatch />} />
              </Routes>
              <ContactsCircle dark={dark} />
              <CookieConsent
                onAccept={() => {
                  window.location.reload();
                }}
                onDecline={() => {
                  window.location.reload();
                }}
                containerClasses="yin d-flex h-auto"
                enableDeclineButton
                buttonText="Accetta tutti i cookie"
                declineButtonText="Accetta solo i cookie essenziali"
                buttonClasses="btn-cookie-accept-all"
                declineButtonClasses="btn-cookie-accept-essential"
              >
                Noi e terze parti selezionate utilizziamo cookie o tecnologie
                simili per finalità tecniche e, con il tuo consenso, anche per
                le finalità di funzionalità, esperienza, misurazione. Il rifiuto
                del consenso può rendere non disponibili le relative funzioni.
                Puoi liberamente prestare, rifiutare o revocare il tuo consenso,
                in qualsiasi momento. Usa il pulsante “Accetta tutti i cookie”
                per acconsentire. Usa il pulsante “Accetta solo cookie
                essenziali” per continuare senza accettare.
              </CookieConsent>
            </Container>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
