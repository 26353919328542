import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";
import { Row } from "react-bootstrap";

function Privacy() {
  return (
    <Row className="mx-2 justify-content-center mb-5 ">
      <h1>NOTA LEGALE SULLA PROPRIETÀ INTELLETTUALE</h1>
      <h3>Disposizioni generali</h3>
      <p>
        Con l'accesso, l'utilizzo e la navigazione del sito Panta Rei studio
        olistico (di seguito definito "Sito Web") accettate, senza limitazioni o
        riserve, di essere vincolati ai seguenti Termini e Condizioni di
        Utilizzo di Rainer Nicole Cristine, per quanto concerne i materiali
        audio, video, grafici, testuali e multimediali presenti all'interno del
        suddetto Sito. Rainer Nicole si riserva il diritto di modificare detti
        Termini e Condizioni di Utilizzo in qualsiasi momento e senza preavviso.
        Le modifiche saranno rese note nelle "Note Legali".
      </p>
      <h4>Proprietà e Gestione</h4>
      <p>
        Questo Sito Web è di proprietà di Rainer Nicole Cristine ed è gestito da
        Rainer Nicole Cristine , controlla e cura questo Sito Web ma non
        garantisce che le informazioni fornite su questo Sito Web o attraverso
        di esso siano adatte e valide per essere utilizzate in ogni luogo.
        Durante l'utilizzo di questo Sito Web, siete responsabili del rispetto
        della normativa locale applicabile agli utilizzatori del web.
      </p>
      <h4>
        Copiright <FontAwesomeIcon icon={regular("copyright")} size={"xs"} />
      </h4>
      <p>
        I diritti d'autore e ogni altro diritto di proprietà intellettuale
        relativo a testi, immagini e ad ogni altro materiale multimediale
        presente in questo Sito Web sono di proprietà di Rainer Nicole Cristine
        o sono stati inseriti con l'autorizzazione del relativo proprietario.
      </p>
      <h4>Proprietà Intellettuale</h4>
      <p>
        Rainer Nicole Cristineo il relativo proprietario mantengono tutti i
        diritti (incluso il diritto d'autore, il diritto sui marchi così come
        ogni altro diritto di proprietà intellettuale) relativi alle
        informazioni fornite su questo Sito Web o attraverso di esso (inclusi
        tutti i testi, le grafiche, i video, il materiale multimediale, gli
        audio ed i loghi).
      </p>
      <h2>Cookies & Privacy Policy</h2>
      <p>
        Panta Rei studio olistico di RAINER Nicole Cristine +39 339 76 17 704
        info@olisticopantarei.it Via Vaprio 60 - Cassano d’Adda (Mi)
      </p>
      <h3>Informativa Privacy</h3>
      <p>
        Ai sensi e per gli effetti di cui l&#39;articolo 13 del Regolamento
        Europeo n. 679 del 27 aprile 2016, relativo alla protezione e tutela
        delle persone fisiche ed altri soggetti in materia di trattamento dei
        dati personali, nonché alla libera circolazione di tali dati e che
        abroga la direttiva 95/46/CE, ed in relazione alle informazioni di cui
        si entrerà in possesso, si informa quanto segue:<br></br> 1. Estremi
        identificativi del Titolare Il Titolare del Trattamento è Panta Rei
        studio olistico di Rainer Nicole Cristine, con sede Via Vaprio 60 –
        Cassano d’Adda (MI) email: info@olisticopantarei.it Il Responsabile del
        Trattamento è unicamente Gatti Alice <br></br>2. Finalità del
        Trattamento Il Trattamento dei dati da Lei forniti è finalizzato
        unicamente all&#39;esecuzione del contratto e ad eventuali
        collaborazioni professionali esterne; i dati verranno utilizzati per
        l&#39;amministrazione, le fatturazioni o l&#39;invio di materiale
        promozionale. Non è contemplato qualsiasi altro tipo di utilizzo.{" "}
        <br></br>3. Modalità del Trattamento I dati personali verranno trattati
        in forma cartacea, informatizzata e telematica ed inseriti nelle
        rispettive e pertinenti banche dati, a cui potranno accedere soltanto i
        funzionari incaricati. Il trattamento potrà anche essere effettuato da
        terzi che forniscono specifici servizi elaborativi, amministrativi o
        strumentali necessari per il raggiungimento delle finalità di cui sopra.
        Tutte le operazioni di trattamento dei dati sono attuate in modo da
        garantire l&#39;integrità, la riservatezza e la disponibilità dei dati
        personali.
        <br></br>4. Periodo di Conservazione dei Dati I dati forniti verranno
        conservati per tutta la durata del rapporto professionale, dalla data di
        compilazione del preventivo, fino al momento di recessione. <br></br>5.
        Conferimento dei Dati Il conferimento dei dati di cui al punto 1 sono
        OBBLIGATORI / facoltativi, ai fini di poter completare la gestione
        amministrativa e l&#39;eventuale rifiuto dell&#39;autorizzazione
        comporta l&#39;annullamento della collaborazione stessa. <br></br>6.
        Ambito di Comunicazione e Diffusione dei Dati In relazione alle finalità
        indicate al punto 2, i dati portano essere comunicati ai seguenti
        soggetti:
        <ul>
          <li>istituti bancari per la gestione di incassi e pagamenti;</li>{" "}
          <li>
            {" "}
            società e studi legali per la tutela dei diritti contrattuali;
          </li>{" "}
          <li>eventuali collaborazioni esterne;</li>
        </ul>{" "}
        <br></br>7. Diritti dell&#39;Interessato  La informiamo che, in qualità
        di Interessato, ha oltre il diritto di proporre reclamo all&#39;Autorità
        di Controllo. I diritti sono qui di seguito elencati, e potrà farli
        valere rivolgendo l&#39;apposita richiesta scritta o inviando una mail
        al Titolare del Trattamento e/o Responsabile del Trattamento, come
        indicati al punto 1.<br></br> Art. 15 – Diritto di Accesso
        L&#39;Interessato ha il diritto di ottenere dal Titolare del Trattamento
        la conferma che sia o meno in corso un Trattamento di dati personali che
        lo riguardano e in tal caso, di ottenere l&#39;accesso ai dati personali
        e alle informazioni riguardanti il Trattamento.<br></br> Art. 16 –
        Diritto di Rettifica<br></br> L&#39;Interessato ha diritto di ottenere
        dal Titolare del Trattamento la rettifica dei dati personali inesatti
        che lo riguardano senza ingiustificato ritardo. Tenuto conto delle
        finalità del Trattamento, l&#39;Interessato ha il diritto di ottenere
        l&#39;integrazione dei dati personali incompleti, anche fornendo una
        dichiarazione integrativa.<br></br> Art. 17 – Diritto alla Cancellazione
        (Diritto all&#39;Oblio)<br></br> L&#39;Interessato ha il diritto di
        ottenere dal Titolare del Trattamento la cancellazione dei dati
        personali che lo riguardano senza ingiustificato ritardo e il Titolare
        del Trattamento ha l&#39;obbligo di cancellare senza ingiustificato
        ritardo i dati personali.<br></br> Art. 18 – Diritto di Limitazione del
        Trattamento<br></br>
        L&#39;Interessato ha il diritto di ottenere dal Titolare del Trattamento
        la limitazione del trattamento quando ricorre una delle seguenti
        ipotesi:{" "}
        <ul>
          {" "}
          l&#39;Interessato contesta l&#39;esattezza dei dati personali, per il
          periodo necessario al Titolare del Trattamento per verificare
          l&#39;esattezza di tali dati personali;  il Trattamento è illecito e
          l&#39;Interessato si oppone alla cancellazione dei dati personali e
          chiede invece che ne sia limitato l&#39;utilizzo;  benché il Titolare
          del trattamento non ne abbia più bisogno ai fini del Trattamento, i
          dati personali sono necessari all&#39;Interessato per
          l&#39;accertamento, l&#39;esercizio o la difesa di un diritto in sede
          giudiziaria;  l&#39;Interessato si è opposto al Trattamento ai sensi
          dell&#39;articolo 21, paragrafo 1, in attesa della verifica in merito
          all&#39;eventuale prevalenza dei motivi legittimi del titolare del
          trattamento rispetto a quelli dell&#39;interessato.
        </ul>
        <br></br> Art. 20 – Diritto alla Portabilità dei Dati<br></br>{" "}
        L&#39;Interessato ha il diritto di ricevere in un formato strutturato,
        di uso comune o leggibile da dispositivo automatico, i dati personali
        che lo riguardano, forniti a un Titolare del Trattamento senza
        impedimenti da parte del Titolare del Trattamento cui li ha forniti.
        Nell&#39;esercitare i propri diritti relativamente alla portabilità dei
        dati a norma del paragrafo 1, l&#39;Interessato ha il diritto di
        ottenere la trasmissione diretta dei dati personali da un Titolare del
        Trattamento all&#39;altro, se tecnicamente fattibile.<br></br> Art. 21 –
        Diritto di Opposizione<br></br>
        L&#39;Interessato ha il diritto di opporsi in qualsiasi momento, per
        motivi connessi alla sua situazione particolare, al Trattamento dei dati
        personali che lo riguardano ai sensi dell&#39;articolo 6, paragrafo 1,
        lettere e) o f), compresa la profanazione sulla base di tali
        disposizioni.<br></br> Art. 22 – Diritto di non essere sottoposto a
        Processo Decisionale Automatizzato, compresa la Profilazione<br></br>{" "}
        L&#39;Interessato ha il diritto di non essere sottoposto a una decisione
        basata unicamente sul Trattamento automatizzato, compresa la
        profilazione, che produca effetti giuridici che lo riguardano o che
        incida in modo analogo significativamente sulla sua persona.
      </p>
      <h3>Dati Trattati sul Web</h3>
      <h4>1. Tipologia di dati</h4>
      <p>
        Come tutti i siti web, anche il presente utilizza log files nei quali
        vengono conservate informazioni raccolte in maniera automatizzata
        durante le visite degli utenti. Le informazioni raccolte potrebbero
        essere le seguenti:
        <ul>
          <li>indirizzo internet protocol (IP);</li>{" "}
          <li>
            {" "}
            tipo di browser e parametri del dispositivo usato per connettersi al
            sito;
          </li>{" "}
          <li>nome dell&#39;internet service provider (ISP);</li>{" "}
          <li> data e orario di visita;</li>{" "}
          <li>
            {" "}
            pagina web di provenienza del visitatore (referral) e di uscita;
          </li>{" "}
          <li> eventualmente il numero di click.</li>
        </ul>
      </p>
      <h4>2. Trattamento dei dati</h4>
      <p>
        Le suddette informazioni sono trattate in forma automatizzata e raccolte
        in forma esclusivamente aggregata al fine di verificare il corretto
        funzionamento del sito, e per motivi di sicurezza. Tali informazioni
        saranno trattate in base ai legittimi interessi del Titolare.
      </p>
      <h4>3. Utilizzo dei dati</h4>
      <p>
        Ai fini di sicurezza (filtri antispam, firewall, rilevazione virus), i
        dati registrati automaticamente possono eventualmente comprendere anche
        dati personali come l&#39;indirizzo Ip, che potrebbe essere utilizzato,
        conformemente alle leggi vigenti in materia, al fine di bloccare
        tentativi di danneggiamento al sito medesimo o di recare danno ad altri
        utenti, o comunque attività dannose o costituenti reato. Tali dati non
        sono mai utilizzati per l&#39;identificazione o la profilazione
        dell&#39;utente, ma solo a fini di tutela del sito e dei suoi utenti,
        tali informazioni saranno trattate in base ai legittimi interessi del
        titolare.
      </p>
      <h4>4. Dati volontariamente forniti</h4>
      <p>
        Qualora il sito consenta l&#39;inserimento di commenti, oppure in caso
        di specifici servizi richiesti dall&#39;utente, ivi compresi la
        possibilità di inviare il Curriculum Vitae per un eventuale rapporto
        lavorativo, il sito rileva automaticamente e registra alcuni dati
        identificativi dell&#39;utente, compreso l&#39;indirizzo mail. Tali dati
        si intendono volontariamente forniti dall&#39;utente al momento della
        richiesta di erogazione del servizio. Inserendo un commento o altra
        informazione l&#39;utente accetta espressamente l&#39;informativa
        privacy, e in particolare acconsente che i contenuti inseriti siano
        liberamente diffusi anche a terzi. I dati ricevuti verranno utilizzati
        esclusivamente per l&#39;erogazione del servizio richiesto e per il solo
        tempo necessario per la fornitura del servizio.
      </p>
      <h4>5. Pubblicazione delle informazioni</h4>
      <p>
        Le informazioni che gli utenti del sito riterranno di rendere pubbliche
        tramite i servizi e gli strumenti messi a disposizione degli stessi,
        sono fornite dall&#39;utente consapevolmente e volontariamente,
        esentando il presente sito da qualsiasi responsabilità in merito ad
        eventuali violazioni delle leggi. Spetta all&#39;utente verificare di
        avere i permessi per l&#39;immissione di dati personali di terzi o di
        contenuti tutelati dalle norme nazionali ed internazionali.
      </p>
      <h1 className="text-center">PRIVACY POLICY</h1>
      <p className="text-center">
        Ai sensi del Regolamento UE n. 2016/679 – Regolamento Europeo in materia
        di protezione dei dati personali
      </p>
      <p>
        Ai sensi degli artt. 13-14 del “Regolamento UE n. 2016/679 (di seguito
        GDPR)”, desideriamo informarLa che il trattamento dei Suoi dati
        personali sarà improntato ai principi di correttezza, liceità e
        trasparenza, nel pieno rispetto del Suo diritto alla riservatezza.
      </p>
      <h4>Finalità del trattamento </h4>
      <p>
        I dati da Lei forniti verranno trattati, solo previo suo specifico
        consenso, per le seguenti finalità:
      </p>
      <ul>
        <li>Invio comunicazioni via mail;</li>
        <li>Contatti telefonici;</li>
        <li>Corretta erogazione dei servizi</li>
        <li>Eventuale proposte di formazione </li>
      </ul>
      <h4>Modalità del trattamento</h4>
      <p>
        Il trattamento dei Suoi dati sarà realizzato per mezzo delle operazioni
        indicate all’ art. 4 n. 2) del GDPR, ossia: raccolta, registrazione,
        organizzazione, conservazione, consultazione, elaborazione,
        modificazione, selezione, estrazione, raffronto, utilizzo,
        interconnessione, blocco, comunicazione, cancellazione e distruzione dei
        dati.
      </p>
      <p>
        Il trattamento dei dati raccolti sarà effettuato sia in forma cartacea
        che con l’ausilio di strumenti informatici, telematici ed automatizzati,
        mediante l’inserimento in archivi gestiti da soggetti a ciò formalmente
        incaricati. I dati verranno trattati per tutta la durata del rapporto e
        conservati per il periodo di tempo previsto da leggi, regolamenti o
        dalla normativa comunitaria e, comunque, per un periodo di tempo non
        superiore a quello necessario per gli scopi per i quali essi sono stati
        raccolti e/o trattati e in ogni caso non superiore ai 10 anni
      </p>
      <h4>Luogo di trattamento</h4>
      <p>
        Il trattamento dei dati personali è realizzato da parte del personale
        operante presso la Sede Operativa, in via Vaprio 60, 20062 Cassano
        d’Adda (MI).
      </p>
      <h4>Accesso ai dati</h4>
      <p>
        I Suoi dati potranno essere resi accessibili per le finalità di cui
        sopra:
      </p>
      <ul>
        <li>
          ai dipendenti e collaboratori del Titolare, nella loro qualità di
          incaricati e/o responsabili interni;
        </li>
        <li>
          - a società terze o ad altri soggetti (es. Studi professionali,
          consulenti, etc) che svolgono attività in outsourcing per conto del
          Titolare, nella loro eventuale qualità di responsabili esterni del
          trattamento e che avranno accesso ai dati con l’opportuna tempistica e
          per le sole finalità legate alla gestione dei suddetti servizi.
        </li>
      </ul>
      <h4>Diffusione e Comunicazione dei dati</h4>
      <p>
        I Suoi dati non saranno oggetto di diffusione e, senza le necessità di
        un Suo espresso consenso – art. 6 lett. b) e c) del GDPR - potranno
        essere comunicati esclusivamente a:
      </p>
      <ul>
        <li>Soggetti esterni formalmente incaricati; </li>
        <li>
          Soggetti pubblici al fine di adempiere ad eventuali obblighi di legge
          (es. necessità di adempiere ad ordini dell’Autorità Giudiziaria);
        </li>
        <li>
          Soggetti privati che intervengano per fornire il servizio da Lei
          richiesto.
        </li>
      </ul>
      <p>
        Ogni ulteriore comunicazione avverrà solo previo Suo esplicito consenso.
      </p>
      <p>
        In ogni caso le informazioni fornite verranno utilizzate esclusivamente
        per il perseguimento dello scopo per il quale dette informazioni sono
        state comunicate e potranno essere diffuse, nel rispetto del Regolamento
        Europeo in materia di protezione dei dati personali, a figure esterne
        all’Azienda solo per finalità tecnico amministrativo contabili, connesse
        agli incarichi affidati.
      </p>
      <h4>Trasferimento dati</h4>
      <p>
        I Suoi dati personali sono conservati su server all’interno dell’Unione
        Europea e/o assoggettati alla normativa europea di protezione dei dati
        personali.
      </p>
      <p>
        Resta in ogni caso inteso che il Titolare, laddove si rendesse
        necessario, avrà facoltà di spostare i server anche in località
        extra-UE.
      </p>
      <p>
        In tal caso, la Titolare assicura fin da ora che il trasferimento dei
        dati in zone extra-UE avverrà in conformità alle disposizioni di legge
        applicabili, previa stipula delle clausole contrattuali standard
        previste dalla Commissione Europea.
      </p>
      <h4>
        Natura del conferimento dei dati e conseguenze del rifiuto a rispondere
      </h4>
      <p>
        Il conferimento dei dati, nell’ambito dei trattamenti descritti, è
        facoltativo.
      </p>
      <p>
        Tuttavia, il mancato conferimento degli stessi comporterà la mancata o
        parziale possibilità, di fornire i servizi sopra indicati.
      </p>
      <p>
        In ogni caso ed in riferimento a tutti i dati personali comunicati
        attraverso il sito web, è escluso il conferimento ed ogni operazione di
        trattamento di dati sensibili e giudiziari.
      </p>
      <p>Il Titolare del trattamento è: dott.ssa Rainer Cristine Nicole</p>
      <h4>Diritti dell’interessato</h4>
      <p>
        Nella Sua qualità di interessato, in ogni momento Lei potrà esercitare i
        Suoi diritti nei confronti del Titolare del trattamento, ai sensi
        dell’art. 15 del GDPR:
      </p>
      <ol type="i">
        <li>
          ottenere la conferma dell'esistenza o meno di dati personali che La
          riguardano, anche se non ancora registrati, e la loro comunicazione in
          forma intelligibile;
        </li>
        <li>
          ottenere l'indicazione: a) dell'origine dei dati personali; b) delle
          finalità e modalità del trattamento; c) della logica applicata in caso
          di trattamento effettuato con l'ausilio di strumenti elettronici; d)
          degli estremi identificativi del titolare, dei responsabili e del
          rappresentante designato ai sensi dell' art. 3, comma 1, GDPR; e) dei
          soggetti o delle categorie di soggetti ai quali i dati personali
          possono essere comunicati o che possono venirne a conoscenza in
          qualità di rappresentante designato nel territorio dello Stato, di
          responsabili o incaricati;
        </li>
        <li>
          ottenere: a) l'aggiornamento, la rettificazione ovvero, quando vi ha
          interesse, l'integrazione dei dati; b) la cancellazione, la
          trasformazione in forma anonima o il blocco dei dati trattati in
          violazione di legge, compresi quelli di cui non è necessaria la
          conservazione in relazione agli Pagina 3 di 3 scopi per i quali i dati
          sono stati raccolti o successivamente trattati; c) l'attestazione che
          le operazioni di cui alle lettere a) e b) sono state portate a
          conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai
          quali i dati sono stati comunicati o diffusi, eccettuato il caso in
          cui tale adempimento si rivela impossibile o comporta un impiego di
          mezzi manifestamente sproporzionato rispetto al diritto tutelato; iv.
          opporsi, in tutto o in parte: a) per motivi legittimi al trattamento
          dei dati personali che La riguardano, ancorché pertinenti allo scopo
          della raccolta. Ove applicabili,
        </li>
      </ol>
      <p>
        Lei ha altresì i diritti di cui agli artt. 16-21 GDPR: - Diritto di
        rettifica (art. 16); - Diritto alla cancellazione - Diritto all’oblio
        (art. 17); - Diritto di limitazione di trattamento (art. 18); - Obbligo
        di notifica in caso di rettifica o cancellazione dei dati personali o
        limitazione del trattamento (art. 19); - Diritto alla portabilità dei
        dati (art. 20); - Diritto di opposizione (art. 21); nonché il diritto di
        reclamo all’Autorità Garante.
      </p>
      <h4>Modalità di esercizio dei diritti</h4>
      <p>
        Potrà in qualsiasi momento esercitare i Suoi diritti rivolgendosi al
        seguente recapito: Info@olisticopantarei.it
      </p>
    </Row>
  );
}

export default Privacy;
