import React from "react";
import CategoryComponent from "../../components/CategoryComponent";
import Data from "../../Data/Categories/TecnicheDiRilassamento";
import { Helmet } from "react-helmet-async";
function TecnicheDiRilassamento({ footer = true }) {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>
          Relax, Zen e Stretching dei Meridiani - Studio Olistico Panta Rei
        </title>
        <meta
          name="description"
          content="Esplora il benessere attraverso lo Zen-Stretching®, una ginnastica morbida che integra stiramento e tonificazione delle catene muscolari lungo i meridiani energetici. Scopri come ogni esercizio personalizzato stimola il flusso di energia vitale, seguendo i principi yin-yang dei meridiani. Approfitta dell'Active Yoga Position e del Massaggio Energetico Spirituale (MES) per un completo riassetto energetico. Sperimenta le Tecniche di Rilassamento e MUDRA, metodi specifici per gestire lo stress, l'ansia e favorire il rilassamento. Partecipa alle Lezioni in coppia e di gruppo, che includono respirazione energetica, pratica meditativa, visualizzazione e rilassamento con cristalli e MUDRA."
        />
        <meta
          name="keywords"
          content="Zen-Stretching, Zen, Stretching, Meridiani energetici, Active Yoga Position, Massaggio Energetico Spirituale, Tecniche di Rilassamento, MUDRA, Lezioni in coppia, Lezioni di gruppo, Studio Olistico, Panta Rei, Mtc, Kinesiologia, Coppettazione, moxa, Moxibustione, Taping, Riflessologia e Dien Chan, Access Bars, Chakra, Fiori Bach e Australiani, Meditazione, Medicina Tradizionale Cinese, Massaggi, Cassano d'Adda, Martesana, Milano,  Terapie Olistiche, Cassano"
        />
        <meta
          property="og:title"
          content="Relax, Zen e Stretching dei Meridiani - Studio Olistico Panta Rei"
        />
        <meta
          property="og:description"
          content="Esplora lo Zen-Stretching® e altre tecniche di relax, stretching e bilanciamento energetico dei meridiani presso lo Studio Olistico Panta Rei. Approfitta di trattamenti come Active Yoga Position, Access Bars, Riflessologia e molto altro."
          data-rh="true"
        />
      </Helmet>
      <CategoryComponent data={Data} footer={footer} />
    </>
  );
}

export default TecnicheDiRilassamento;
