import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import Treatment from "./Treatment";
import DataArray from "../Data/Data";

function CategoryComponent(props) {
  return (
    <Row className="justify-content-center align-items-center">
      <Row className="text-center justify-content-center">
        <h1 className="justify-content-center">
          <Image src={props.data.icon} className="title-icon"></Image>{" "}
          {props.data.name}
        </h1>
      </Row>
      <Row className="mx-2 text-center text-md-start">
        {props.data.description}
      </Row>
      {props.data.treatments.map((treatment, id) => {
        return (
          <>
            <Treatment
              key={id}
              title={treatment.name}
              image={treatment.image}
              descriptionBig={treatment.description}
              descriptionSmall={treatment.descriptionSmall}
            />
          </>
        );
      })}
      <div className="mb-4 justify-content-center align-items-center d-flex flex-row">
        {DataArray.yin.map((cat, id) => {
          if (props.footer && cat.name != props.data.name) {
            return (
              <Link key={id} to={cat.link} className="mx-2 big-scale-hover">
                <Image className="image-icon" src={cat.icon} fluid></Image>
              </Link>
            );
          }
        })}
      </div>
    </Row>
  );
}

export default CategoryComponent;
