import React from "react";
import { Col } from "react-bootstrap";

import MedicinaTradizionaleCinese from "./MedicinaTradizionaleCinese";
import Massaggi from "./Massaggi";
import TecnicheDiRilassamento from "./TecnicheDiRilassamento";
import Riflessologia from "./Riflessologia";
import FitoterapiaEtc from "./FitoterapiaEtc";
import TapingEnergetico from "./TapingEnergetico";
import { Helmet } from "react-helmet-async";
import TerapieTradizionali from "./TerapieTradizionali";

function Trattamenti() {
  return (
    <>
      <Col className="mb-5 justify-content-center align-items-center ">
        <MedicinaTradizionaleCinese footer={false} />
        <hr />
        <Massaggi footer={false} />
        <hr />
        <TecnicheDiRilassamento footer={false} />
        <hr />
        <Riflessologia footer={false} />
        <hr />
        <FitoterapiaEtc footer={false} />
        <hr />
        {/*<TapingEnergetico />
      <hr />*/}
        <TerapieTradizionali />
      </Col>
      <Helmet prioritizeSeoTags>
        <title>Trattamenti - Studio Olistico Panta Rei</title>
        <meta
          property="og:title"
          content="Trattamenti - Studio Olistico Panta Rei"
        />
        <meta
          name="description"
          content="Esplora una vasta gamma di trattamenti olistici presso Studio Olistico Panta Rei. Massaggi, riflessologia, medicina tradizionale cinese e altro per il tuo benessere. Scopri le terapie che si adattano alle tue esigenze."
        />
        <meta
          name="keywords"
          content="trattamenti olistici, massaggi, medicina tradizionale cinese, riflessologia, access bars, zen stretching, fioriterapia, aromaterapia, terapie tradizionali, Studio Olistico Panta Rei, benessere, Mtc, Kinesiologia, Coppettazione, moxa, Moxibustione, Taping, Riflessologia e Dien Chan, Chakra, Fiori Bach e Australiani, Meditazione, Cassano d'Adda, Martesana, Milano,  Terapie Olistiche, Cassano"/>
        <meta
          property="og:description"
          content="Esplora il benessere olistico presso lo Studio Panta Rei a Cassano D'Adda. Nicole Rainer ti guiderà attraverso Terapie Tradizionali, Fisioterapia, Medicina Cinese e Terapie Manuali per ristabilire l'equilibrio di corpo, mente e spirito. Scopri il percorso completo di benessere con la nostra approfondita gamma di trattamenti. 
      "
          data-rh="true"
        />
      </Helmet>
    </>
  );
}

export default Trattamenti;
