import React from "react";
import { Row } from "react-bootstrap";
import CategoryComponent from "../../components/CategoryComponent";
import Data from "../../Data/Categories/FloriterapiaeAromaterapia";
import { Helmet } from "react-helmet-async";
function FitoterapiaEtc({ footer = true }) {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Floriterapia e Aromaterapia - Studio Olistico Panta Rei</title>
        <meta
          name="description"
          content="Scopri la potenza della Floriterapia e dell'Aromaterapia presso lo Studio Olistico Panta Rei. Attraverso la Kinesiologia Applicata, valutiamo gli squilibri del corpo e individuiamo i rimedi più adatti per ristabilire l'equilibrio fisico, emotivo ed energetico. Esplora il mondo dei Fiori di Bach, Fiori Australiani e della Fitoterapia, estratti naturali che supportano il benessere interiore. Approfondisci l'Aromaterapia, dove gli oli essenziali diventano un alleato potente per potenziare gli effetti dei trattamenti sulla sfera emotiva ed energetica."
        />
        <meta
          name="keywords"
          content="Floriterapia, Aromaterapia, Kinesiologia, Fiori di Bach, Fiori Australiani, Fitoterapia, Oli essenziali, Benessere interiore, Equilibrio energetico, Studio Olistico, Panta Rei, Mtc, Coppettazione, moxa, Moxibustione, Taping, Riflessologia e Dien Chan, Access Bars, Chakra, Meditazione, Medicina Tradizionale Cinese, Massaggi, Cassano d'Adda, Martesana, Milano,  Terapie Olistiche, Cassano"
        />
        <meta
          property="og:title"
          content="Floriterapia e Aromaterapia - Studio olistico Panta Rei"
        />
        <meta
          property="og:description"
          content="Esplora la Floriterapia e l'Aromaterapia presso lo Studio Olistico Panta Rei. Utilizziamo la Kinesiologia per individuare squilibri e personalizzare i trattamenti. Scopri i benefici dei Fiori di Bach, Fiori Australiani e dell'Aromaterapia con oli essenziali per il benessere emotivo ed energetico."
          data-rh="true"
        />
      </Helmet>
      <CategoryComponent data={Data} footer={footer} />
    </>
  );
}

export default FitoterapiaEtc;
