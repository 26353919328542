import React from "react";
import { Col, Row, Image } from "react-bootstrap";

function Treatment2({ children, title, image }) {
  return (
    <Row className="service-container m-0 p-0 mt-3" id={title}>
      <Col className="justify-content-center d-flex d-md-none flex-column p-2 p-md-5">
        <h2 className="mt-3 text-center text-md-start">{title}</h2>
      </Col>
      <Col
        md={5}
        lg={4}
        className="justify-content-center d-flex flex-column p-2 px-5 p-md-5"
      >
        <Image src={image} rounded fluid className=""></Image>
      </Col>
      <Col className="justify-content-center text-center d-flex flex-column p-2 p-md-5">
        <h2 className="mt-3 text-center text-md-start d-none d-md-flex">
          {title}
        </h2>
        <p className="text-center text-md-start">{children}</p>
      </Col>
    </Row>
  );
}

export default Treatment2;
