import React from "react";
import { Carousel, Image } from "react-bootstrap";

import Img1 from "../images/studio/studio1.jpg";
import Img2 from "../images/studio/studio2.jpg";
import Img3 from "../images/studio/studio3.jpg";
import Img4 from "../images/studio/studio4.jpg";
import Img5 from "../images/studio/studio5.jpg";
import Img6 from "../images/studio/studio6.jpg";
import Img7 from "../images/studio/studio7.jpg";
import Img8 from "../images/studio/studio8.jpg";
import Img9 from "../images/studio/studio9.jpg";
import collage from "../images/studio/collage.png";

function StudioCarousel() {
  const images = [
    collage,
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
  ];
  return (
    <Carousel
      variant="dark"
      align="center"
      className="carousel-container"
      indicators={false}
    >
      {images.reverse().map((image, k) => {
        return (
          <Carousel.Item key={k} className="carousel-item">
            <Image src={image} className="d-block carousel-img" fluid />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default StudioCarousel;
