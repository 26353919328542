import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import Treatment2 from "../../components/Treatment2";
import terapieManualiImage from "../../images/treatmentsIcons/terapia-manuale.png";
import terapieFisicheImage from "../../images/treatmentsIcons/terapie-fisiche.png";
import tutoriImage from "../../images/treatmentsIcons/tutori.png";
import medicinaTradizionaleImageLight from "../../images/treatmentsIcons/tradizionale-light.png";
import medicinaTradizionaleImageDark from "../../images/treatmentsIcons/tradizionale-dark.png";
import fisioterapiaeriabilitazioneImage from "../../images/treatmentsIcons/fisioterapia.png";
import { CarioniSiteLink } from "../../Utils/links";
import carioniImage from "../../images/collaborazioni/carioni-logo.svg";
import { Helmet } from "react-helmet-async";
function TerapieTradizionali() {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Terapie Tradizionali - Studio Olistico Panta Rei</title>
        <meta
          name="description"
          content="Esplora le Terapie Tradizionali presso il Centro di Fisioterapia Carioni. Offriamo trattamenti di Fisioterapia e Riabilitazione per una vasta gamma di condizioni muscolari, neurologiche, post-traumatiche e post-chirurgiche. Le Terapie Manuali includono tecniche di massaggio decontratturante, rilassante, sportivo e mobilizzazione articolare. Sfruttiamo anche Terapie Fisiche con apparecchi elettromedicali avanzati come laserterapia, neurostimolatore interattivo (Interx), ElettroNeuroFeedback (ENF) e onde d'urto. Il KinesioTaping offre bendaggi meccanici, linfatici, sportivi e tutori termoplastici per la riabilitazione della mano."
        />
        <meta
          name="keywords"
          content="Fisioterapia, Riabilitazione, Terapie Tradizionali, Terapie Manuali, Terapie Fisiche, KinesioTaping, Centro di Fisioterapia Carioni, Riabilitazione Muscoloscheletrica, Riabilitazione Neurologica, Riabilitazione Sportiva, Riabilitazione della Mano, Massaggio decontratturante, Laserterapia, Onde d'urto, Panta Rei, Studio Olistico, Mtc, Kinesiologia, Coppettazione, moxa, Moxibustione, Taping, Riflessologia e Dien Chan, Access Bars, Chakra, Fiori Bach e Australiani, Meditazione, Medicina Tradizionale Cinese, Massaggi, Cassano d'Adda, Martesana, Milano,  Terapie Olistiche, Cassano"/>
        <meta
          property="og:title"
          content="Terapie Tradizionali - Studio Olistico Panta Rei"
        />
        <meta
          property="og:description"
          content="Scopri le terapie tradizionali di fisioterapia e riabilitazione offerte presso il Centro Carioni, guidate con competenza e professionalità da Nicole, esperta terapista di Panta Rei. Percorsi riabilitativi mirati per diverse condizioni muscolari, neurologiche, post-traumatiche, post-chirurgiche e sportive, finalizzati al recupero ottimale della funzionalità corporea."
          data-rh="true"
        />
      </Helmet>
      <Row className=" mb-2 justify-content-center align-items-center">
        <Row className="text-center">
          <h1>Terapie Tradizionali</h1>
          <Row className="mx-2 justify-content-center text-center mb-5">
            <Link
              to="/contatti#fisioterapia-carioni"
              className="text-decoration-none link-bold-hover "
            >
              I seguenti trattamenti sono erogati presso il Centro di
              Fisioterapia Carioni
            </Link>
            <Col xs={6} md={3} lg={2} className="my-2">
              <Link to="/contatti#fisioterapia-carioni">
                <div className="scale-hover h-100 justify-content-center align-items-center d-flex flex-wrap ">
                  <div className="m-3 w-100">
                    <Image
                      src={carioniImage}
                      alt="Fisioterapia Carioni"
                      fluid
                      className="w-100"
                    />
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </Row>
        <Row className="mx-2">
          <Treatment2
            title="Fisioterapia e Riabilitazione"
            image={fisioterapiaeriabilitazioneImage}
          >
            Insieme di tecniche riabilitative che mirano alla prevenzione ed al
            recupero della massima funzionalità possibile di tutte le strutture
            corporee:
            <ul className="mt-2">
              <li>
                <b>Riabilitazione Muscoloscheletrica</b> (lombalgie,
                cervicalgie, spalla dolorosa, spalla congelata, epicondiliti,
                epitrocleiti, training del passo, contratture, tendiniti,
                infiammazioni articolari,...)
              </li>
              <li>
                <b>Riabilitazione Neurologica</b> (ictus, sclerosi multipla,
                parkinson, neuropatie periferiche,...)
              </li>
              <li>
                <b>Riabilitazione post-traumatica </b> (distorsioni, fratture,
                lussazioni, colpi di frusta,...)
              </li>
              <li>
                <b>Riabilitazione post-chirurgica</b> (protesi di anca,
                ginocchio, stabilizzazioni con placche e viti, ricostruzione
                cuffia rotatori spalla,...)
              </li>
              <li>
                <b>Riabilitazione Sportiva</b> (ricostruzione legamento crociato
                anteriore, strappi muscolari, stiramenti, sutura tendine
                achille, recupero del gesto atletico,...)
              </li>
              <li>
                <b>Riabilitazione della Mano</b> (fratture polso e mano, lesioni
                tendinee, rizoartrosi, tunnel carpale, sindrome di De Quervain,
                lesioni legamentose,...)
              </li>
            </ul>
          </Treatment2>
          <Treatment2 title="Terapie Manuali" image={terapieManualiImage}>
            Tecniche di massaggio decontratturante, rilassante, sportivo,
            trattamento della fascia, mobilizzazione articolare e dei nervi
            periferici (neurodinamica).
          </Treatment2>
          <Treatment2 title="Terapie Fisiche" image={terapieFisicheImage}>
            Impiego di apparecchi elettromedicali come ausilio antinfiammatorio,
            altalgico e meccanico (l aserterapia, neurostimolatore interattivo
            (Interx), ElettroNeuroFeedback (ENF), onde d'urto, ...)
          </Treatment2>
          <Treatment2 title="KinesioTaping" image={tutoriImage}>
            Bendaggi meccanici, linfatici, sportivi e{" "}
            <b>tutori termoplastici</b> per la riabilitazione della <b>mano</b>.
          </Treatment2>
        </Row>
      </Row>
    </>
  );
}

export default TerapieTradizionali;
