import React from "react";
import { Col, Row, ListGroup, Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../images/loghi/logo-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  CarioniAddress,
  CarioniMail,
  CarioniMailLink,
  CarioniMapsLink,
  CarioniTel,
  CarioniTelLink,
  CarioniWhatsapp,
  CarioniWhatsappLink,
  PantaReiAddress,
  PantaReiFacebookLink,
  PantaReiInstagramLink,
  PantaReiLinkedinLink,
  PantaReiMail,
  PantaReiMailLink,
  PantaReiMapsLink,
  PantaReiPIVA,
  PantaReiTel,
  PantaReiTelLink,
  PantaReiWhatsappLink,
} from "../Utils/links";
import { resetCookieConsentValue } from "react-cookie-consent";

function Footer() {
  const colSize = 5;
  const spacer = 1;
  return (
    <div className="yin mt-auto">
      <Container fluid>
        <Row className="mt-0 my-4 justify-content-center">
          <Col xs={12} lg={colSize * 2} className="text-center text-lg-start">
            {/*<Image
              src={logo}
              width={"100"}
              alt="logo olistico Panta Rei"
              className="mt-1 img-responsive center-block d-block mx-auto"
  ></Image>*/}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col sm={spacer} className="left-spacer"></Col>
          <Col xs={12} lg={colSize} className="olistico-info mb-0 mb-lg-0">
            <ListGroup className="" variant="flush">
              <Row>
                <ListGroup.Item
                  bsPrefix="list-unstyled"
                  className="text-center  me-2"
                >
                  Studio Olistico Panta Rei
                </ListGroup.Item>
              </Row>
              <hr />
              <Row>
                <ListGroup.Item
                  bsPrefix="list-unstyled"
                  className="text-center  me-2"
                >
                  <a
                    href={PantaReiMapsLink}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-decoration-none link-bold-hover"
                  >
                    {PantaReiAddress}
                  </a>
                </ListGroup.Item>
              </Row>
              <Row>
                <ListGroup.Item
                  bsPrefix="list-unstyled"
                  className="text-center me-2"
                >
                  <a
                    href={PantaReiTelLink}
                    className="text-decoration-none link-bold-hover"
                  >
                    Telefono: {PantaReiTel}
                  </a>
                </ListGroup.Item>
              </Row>
              <Row>
                <ListGroup.Item
                  bsPrefix="list-unstyled"
                  className="text-center me-2"
                >
                  <a
                    href={PantaReiMailLink}
                    className="text-decoration-none link-bold-hover"
                  >
                    Email: {PantaReiMail}
                  </a>
                </ListGroup.Item>
              </Row>
              <Row>
                <ListGroup.Item
                  bsPrefix="list-unstyled"
                  className="text-center me-2"
                >
                  P. IVA: {PantaReiPIVA}
                </ListGroup.Item>
              </Row>
            </ListGroup>
          </Col>
          <Col sm={spacer} className="right-spacer"></Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12} className="text-center justify-content-center">
            <ListGroup.Item bsPrefix="list-unstyled text-center">
              <ListGroup horizontal className="justify-content-center">
                <ListGroup.Item bsPrefix="list-unstyled" className="mx-1">
                  <a
                    href={PantaReiWhatsappLink}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-decoration-none whatsapp-icon link-bold-hover"
                  >
                    <FontAwesomeIcon icon={brands("whatsapp")} size="xl" />
                  </a>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix="list-unstyled" className="mx-1">
                  <a
                    href={PantaReiInstagramLink}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-decoration-none instagram-icon link-bold-hover"
                  >
                    <FontAwesomeIcon icon={brands("instagram")} size="xl" />
                  </a>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix="list-unstyled" className="mx-1">
                  <a
                    href={PantaReiFacebookLink}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-decoration-none facebook-icon link-bold-hover"
                  >
                    <FontAwesomeIcon
                      icon={brands("facebook-square")}
                      size="xl"
                    />
                  </a>
                </ListGroup.Item>
                <ListGroup.Item bsPrefix="list-unstyled" className="mx-1">
                  <a
                    href={PantaReiLinkedinLink}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-decoration-none linkedin-icon  link-bold-hover"
                  >
                    <FontAwesomeIcon icon={brands("linkedin")} size="xl" />
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            &copy;{new Date().getFullYear()} Studio Olistico PANTA REI | Tutti i
            diritti riservati |{" "}
            <Link to="privacy" className="text-decoration-none  link-hover">
              Note Legali
            </Link>{" "}
            |{" "}
            <Link
              to=""
              className="text-decoration-none  link-hover"
              onClick={() => {
                resetCookieConsentValue();
                window.location.reload();
              }}
            >
              Preferenze Cookie
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
