import Category from "../Category";
import TreatmentObject from "../TreatmentObject";
import React from "react";

import icon from "../../images/treatmentsIcons/terapie-fisiche.png";

const TerapieFisiche = new Category(
  "Terapie Fisiche",
  [],
  "/trattamenti/terapie-tradizionali",
  icon
);

export default TerapieFisiche;
