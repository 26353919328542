import React from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { ListGroup, Row, Col, ListGroupItem } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  PantaReiAddress,
  PantaReiMail,
  PantaReiMailLink,
  PantaReiMapsLink,
  PantaReiTel,
  PantaReiTelLink,
  PantaReiWhatsappLink,
  PantaReiInstagramLink,
  PantaReiLinkedinLink,
  CarioniMapsLink,
  CarioniAddress,
  CarioniTelLink,
  CarioniTel,
  CarioniMailLink,
  CarioniMail,
  CarioniWhatsappLink,
  CarioniSiteLink,
  PantaReiFacebookLink,
} from "../Utils/links";
import { Helmet } from "react-helmet-async";
function Contatti() {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Contatti - Studio Olistico Panta Rei</title>
        <meta
          name="description"
          content="Contattaci presso lo Studio Olistico Panta Rei a Cassano d'Adda. Situato in Via Vaprio 60, 20062, riceviamo solo su appuntamento. Per prenotazioni, contattaci tramite WhatsApp al numero +39 3397617704 o chiamando lo stesso numero. Puoi anche inviarci un'email a info@olisticopantarei.it. Seguici su Instagram @olisticopantarei per aggiornamenti e ispirazioni."
        />
        <meta
          name="keywords"
          content="Contatti Studio Olistico, Panta Rei, Cassano d'Adda, Via Vaprio, WhatsApp, Telefono, Email, Instagram, Linkedin, Facebook, Studio Olistico, Mtc, Kinesiologia, Coppettazione, moxa, Moxibustione, Taping, Riflessologia e Dien Chan, Access Bars, Chakra, Fiori Bach e Australiani, Meditazione, Medicina Tradizionale Cinese, Massaggi, Martesana, Milano, Terapie Olistiche, Cassano"
        />

        <meta
          property="og:title"
          content="Contatti - Studio Olistico Panta Rei"
        />
        <meta
          property="og:description"
          content="Scopri come raggiungerci a Cassano D'Adda. Prenota una consulenza per il tuo benessere o contattaci su WhatsApp, telefono e social. Studio Olistico Panta Rei: il tuo percorso verso l'equilibrio."
          data-rh="true"
        />
      </Helmet>
      <Row className="px-2 min-page-fill py-2 ">
        <Col
          lg={getCookieConsentValue() === "true" ? 5 : 12}
          className=" flex-grow-1 d-flex flex-column justify-content-center align-items-center text-center"
        >
          <Row className="w-100 my-4">
            <h2>Studio Olistico Panta Rei</h2>
          </Row>
          <Row className="w-100 mb-2 ">
            <a
              href={PantaReiMapsLink}
              className="text-decoration-none link-bold-hover"
            >
              {PantaReiAddress} <FontAwesomeIcon icon={solid("location-dot")} />
            </a>
          </Row>
          <Row className="w-100 mb-2">
            <a
              href={PantaReiWhatsappLink}
              className="text-decoration-none link-bold-hover whatsapp-icon"
            >
              WhatsApp <FontAwesomeIcon icon={brands("whatsapp")} size="lg" />
            </a>
          </Row>
          <Row className="w-100 mb-2">
            <a
              href={PantaReiMailLink}
              className="text-decoration-none link-bold-hover"
            >
              {PantaReiMail} <FontAwesomeIcon icon={solid("envelope")} />
            </a>
          </Row>
          <Row className="w-100 mt-3 mb-4">
            <ListGroup horizontal className="justify-content-center mx-0 px-0">
              <ListGroup.Item bsPrefix="list-unstyled" className="mx-1">
                <a
                  href={PantaReiTelLink}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-decoration-none phone-icon link-hover"
                >
                  <FontAwesomeIcon icon={solid("square-phone")} size="xl" />
                </a>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix="list-unstyled" className="mx-1">
                <a
                  href={PantaReiInstagramLink}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-decoration-none instagram-icon link-hover"
                >
                  <FontAwesomeIcon icon={brands("instagram")} size="xl" />
                </a>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix="list-unstyled" className="mx-1">
                <a
                  href={PantaReiFacebookLink}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-decoration-none facebook-icon link-hover"
                >
                  <FontAwesomeIcon icon={brands("facebook-square")} size="xl" />
                </a>
              </ListGroup.Item>
              <ListGroup.Item bsPrefix="list-unstyled" className="mx-1">
                <a
                  href={PantaReiLinkedinLink}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-decoration-none linkedin-icon link-hover"
                >
                  <FontAwesomeIcon icon={brands("linkedin")} size="xl" />
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Row>
          <Row className="w-100 mb-2 text-decoration-none link-bold justify-content-center">
            <div className="d-flex align-item-center justify-content-center ">
              <p className="me-1">Si riceve solo su appuntamento.</p>
              <FontAwesomeIcon
                icon={solid("clock")}
                size="xl"
              ></FontAwesomeIcon>
            </div>
          </Row>
        </Col>
        {getCookieConsentValue() === "true" ? (
          <Col
            lg={7}
            className=" flex-grow-1  px-lg-5 py-5 d-flex flex-column justify-content-center align-items-center text-center"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2793.7661858558185!2d9.517448015501783!3d45.55502887910214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47814bb4b6d5b427%3A0x86b8bd7c7dba138!2sPanta%20Rei%20Studio%20Olistico!5e0!3m2!1sit!2sit!4v1677944471836!5m2!1sit!2sit"
              style={{ border: 0, borderRadius: "10px" }}
              allowFullScreen=""
              loading="lazy"
              className="w-100 h-100 map"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        ) : (
          ""
        )}
      </Row>
      {
        //----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
      }
      <hr />
      <Row className="py-4"></Row>
      <Row className="px-2 min-page-fill py-2 ref-container">
        <div id="fisioterapia-carioni" className="ref-anchor"></div>
        <Col
          lg={getCookieConsentValue() === "true" ? 5 : 12}
          className=" flex-grow-1 d-flex flex-column justify-content-center align-items-center text-center"
        >
          <Row className="w-100 my-4">
            <h2>Centro di Fisioterapia Carioni</h2>
          </Row>
          <Row className="w-100 mb-2 ">
            <a
              href={CarioniMapsLink}
              className="text-decoration-none link-bold-hover"
            >
              {CarioniAddress} <FontAwesomeIcon icon={solid("location-dot")} />
            </a>
          </Row>
          <Row className="w-100 mb-2">
            <a
              href={CarioniTelLink}
              className="text-decoration-none link-bold-hover"
            >
              {CarioniTel} <FontAwesomeIcon icon={solid("mobile-screen")} />
            </a>
          </Row>
          <Row className="w-100 mb-2">
            <a
              href={CarioniMailLink}
              className="text-decoration-none link-bold-hover"
            >
              {CarioniMail} <FontAwesomeIcon icon={solid("envelope")} />
            </a>
          </Row>
          <Row className="w-100 mb-2">
            <a
              href={CarioniWhatsappLink}
              className="text-decoration-none link-bold-hover whatsapp-icon"
            >
              WhatsApp <FontAwesomeIcon icon={brands("whatsapp")} size="lg" />
            </a>
          </Row>
          <Row className="w-100 mb-4">
            <a
              href={CarioniSiteLink}
              className="text-decoration-none link-bold-hover "
            >
              Sito Web <FontAwesomeIcon icon={solid("link")} />
            </a>
          </Row>
        </Col>
        {getCookieConsentValue() === "true" ? (
          <Col
            lg={7}
            className=" flex-grow-1  px-lg-5 py-5 d-flex flex-column justify-content-center align-items-center text-center"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.0451445960316!2d9.5094127155173!3d45.529297079101745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47814aea56a855f1%3A0x96e9c57187978d93!2sCentro%20di%20Fisioterapia%20Carioni!5e0!3m2!1sit!2sit!4v1653510358770!5m2!1sit!2sit"
              style={{ border: 0, borderRadius: "10px" }}
              allowFullScreen=""
              loading="lazy"
              className="w-100 h-100 map"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </>
  );
}

export default Contatti;
