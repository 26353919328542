import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import StudioCarousel from "../components/StudioCarousel";

import greenImg from "../images/studio/studio6.jpg";
import bioediliziaImg from "../images/studio/studio9.jpg";
import integritaImg from "../images/studio/studio4.jpg";
import { Helmet } from "react-helmet-async";
function Studio() {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Lo Studio - Studio Olistico Panta Rei</title>
        <meta
          name="description"
          content="Scopri lo Studio Olistico Panta Rei, un'oasi di benessere a due passi dal centro di Cassano d'Adda. Completamente immerso nel silenzio e nella tranquillità della natura, lo studio è realizzato secondo i principi della bioedilizia, costruito in legno per ridurre al minimo l'inquinamento indoor. L'integrità con l'ambiente è garantita dal Tetto Verde, dalla Pompa di Calore con scambio geotermico e dai Pannelli Fotovoltaici, rendendo l'edificio a energia quasi zero. Green, bio e plastic-free, lo studio utilizza arredamento, tessuti e prodotti igienizzanti seguendo la logica di tutela dell'ambiente. Il locale è costantemente disinfettato, igienizzato e purificato con cristalli, essenze ed incensi."
        />
        <meta
          name="keywords"
          content="Studio Olistico, Panta Rei, Bioedilizia, impatto Zero, Tetto Verde, Pompa di Calore, Pannelli Fotovoltaici, Green, Bio, Plastic Free, Benessere, Cassano d'Adda, Ambiente Naturale, Disinfezione, Purificazione, Cristalli, Essenze, Incensi, Mtc, Kinesiologia, Coppettazione, moxa, Moxibustione, Taping, Riflessologia e Dien Chan, Access Bars, Chakra, Fiori Bach e Australiani, Meditazione, Medicina Tradizionale Cinese, Massaggi, Martesana, Milano,  Terapie Olistiche, Cassano"
        />
        <meta
          property="og:title"
          content="Lo Studio - Studio Olistico Panta Rei"
        />
        <meta
          property="og:description"
          content="Lo Studio Olistico Panta Rei a Cassano D'Adda, dedicato al benessere olistico attraverso la Medicina Tradizionale Cinese e trattamenti specializzati. 
          La struttura è realizzata secondo i principi della bioedilizia, offrendo un ambiente ENERGIA QUASI ZERO' con Tetto Verde, Pompa di Calore geotermica e Pannelli Fotovoltaici. 
          Arredato seguendo una logica green, bio e plastic-free, lo studio promuove la tranquillità e l'armonia nella natura."
          data-rh="true"
        />
      </Helmet>
      <Row className="mx-2 justify-content-center text-center mb-5">
        <div className="mb-5 d-flex flex-column">
          <h1>Lo Studio</h1>
          <p>
            Uno spazio privato a due passi dal centro, completamente immerso nel
            silenzio, nella pace e nella tranquillità della natura per staccare
            dalla routine e dalla frenesia della quotidianità.
          </p>
          <div className="my-2">
            <StudioCarousel />
          </div>
        </div>
        <Row className="justify-content-around text-center">
          <Col lg={5} className="p-4 studio-card scale-hover ">
            <h2 className="mt-4 d-sm-flex d-md-none mb-4">La bioedilizia</h2>
            <Image src={bioediliziaImg} fluid rounded className="mb-4" />
            <h2 className="justify-content-center mt-4 d-md-flex d-none">
              La bioedilizia
            </h2>
            <p>
              Lo Studio Olistico Panta Rei è stato realizzato secondo i principi
              della bioedilizia: è costruito totalmente in legno. Sono ridotti
              al minimo i fenomeni di inquinamento “indoor”, dovuti ad emissioni
              inquinanti dei materiali da costruzione.
            </p>
          </Col>
          <Col lg={5} className=" p-4 studio-card scale-hover ">
            <h2 className="mt-4 d-sm-flex d-md-none mb-4">
              L'integrità con l'ambiente
            </h2>
            <Image src={integritaImg} fluid rounded className="mb-4" />
            <h2 className="justify-content-center mt-4 d-md-flex d-none">
              L'Integrità con l'ambiente{" "}
            </h2>
            <p>
              Il Tetto Verde, l’impiego della Pompa di Calore con scambio
              geotermico e la produzione di energia elettrica tramite i Pannelli
              Fotovoltaici, qualificano l’edificio a “ENERGIA QUASI ZERO”.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-around text-center">
          <Col lg={5} className=" p-4 studio-card  scale-hover ">
            <h2 className="mt-4 d-sm-flex d-md-none mb-4">
              Green, bio e plastic free
            </h2>
            <Image src={greenImg} fluid rounded className="mb-4" />
            <h2 className="justify-content-center mt-4 d-md-flex d-none">
              Green, bio e plastic free{" "}
            </h2>
            <p>
              Arredamento, tessuti e complementi, prodotti utilizzati
              (igienizzanti, olii, candele…) seguono la logica green, bio,
              plastic-free ed handmade nella visione di tutela dell’uomo e
              dell’ambiente e della riduzione al minimo dell’impatto inquinante.
              Il locale, inoltre, è costantemente disinfettato ed igienizzato
              oltre che “purificato e ricaricato” con cristalli, essenze ed
              incensi.
            </p>
          </Col>
        </Row>
      </Row>
    </>
  );
}

export default Studio;
