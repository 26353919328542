import React from "react";
import { Image, Row, Col } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";

import logo from "../../images/loghi/logo.png";
import logoWhite from "../../images/loghi/logo-white.png";
import olisticodark from "../../images/treatmentsIcons/olistico-dark.png";
import olisticolight from "../../images/treatmentsIcons/olistico-light.png";
import tradizionaledark from "../../images/treatmentsIcons/tradizionale-dark.png";
import tradizionalelight from "../../images/treatmentsIcons/tradizionale-light.png";
import { Helmet } from "react-helmet-async";
import Data from "../../Data/Data";

function HomePageMobile(props) {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Studio Olistico Panta Rei</title>
        <meta property="og:title" content="Studio olistico Panta Rei" />
        <meta
          name="description"
          content="Scopri il benessere personale con i trattamenti della Medicina Tradizionale Cinese e i massaggi esclusivi offerti da Nicole, l'esperta dello Studio Olistico Panta Rei. Dalle tecniche di cromopuntura e coppettazione cinese all'auricoloterapia, moxibustione e gua sha viso, fino ai massaggi come Aroma Massage, Massaggio Bioenergetico e Thai Massage, Nicole personalizza percorsi di guarigione per armonizzare il tuo corpo, mente e spirito. Sperimenta l'arte millenaria della medicina tradizionale cinese e lasciati trasportare in uno stato di relax profondo. Prenota oggi per iniziare il tuo viaggio verso il benessere olistico."
        />
        <meta
          name="keywords"
          content="Panta Rei, Studio Olistico, Mtc, Kinesiologia, Coppettazione, moxa, Moxibustione, Taping, Riflessologia e Dien Chan, Access Bars, Chakra, Fiori Bach e Australiani, Meditazione, Medicina Tradizionale Cinese, Massaggi, Cassano d'Adda, Martesana, Milano,  Terapie Olistiche, Cassano, Cromopuntura, Coppettazione Cinese, Auricoloterapia, Moxibustione, Gua Sha Viso, Aroma Massage, Massaggio Bioenergetico, Thai Massage"
        />

        <meta
          property="og:description"
          content="Scopri il benessere attraverso la Medicina Olistica nel nostro studio a Cassano D'Adda. 
      Offriamo trattamenti di Medicina Tradizionale Cinese come Massaggi Energetico-Vibrazionali, Riflessologia, Access Bars, Relax e Stretching dei Meridiani, Floriterapia, Fisioterapia e molto altro. 
      Prenditi spazio per te e goditi la cura olistica presso lo Studio Panta Rei."
          data-rh="true"
        />
      </Helmet>
      <div className=" mx-2 px-0 justify-content-center text-center">
        <div className="min-page-fill d-flex flex-column justify-content-center align-items-center text-center">
          <h1 className="d-none">Panta Rei</h1>
          <h2 className="d-none">Studio Olistico</h2>
          <Row className="mx-0 px-0 my-3">
            <blockquote className="blockquote text-center">
              <p>
                <cite>
                  Conoscere te stesso è il principio di tutta la saggezza.
                </cite>
              </p>
              <footer className="blockquote-footer">Aristotele</footer>
            </blockquote>
          </Row>
          <Row className="my-4">
            <Image src={props.dark ? logoWhite : logo} fluid />
          </Row>
          <Row className=" justify-content-center my-3 align-items-center">
            <blockquote className="blockquote text-center  justify-content-center  align-items-center">
              <p className="">
                <cite className="">
                  "Colui che conosce tutte le risposte, non ha fatto tutte le
                  domande."
                </cite>
              </p>
              <footer className="blockquote-footer">Confucio</footer>
            </blockquote>
          </Row>
        </div>
        <Row className="mb-5">
          <Col
            xs={6}
            className="p-2 text-center  justify-content-center  align-items-center link-hover"
            as={Link}
            to="/#trattamenti-olistici"
          >
            <Image
              src={props.dark ? olisticodark : olisticolight}
              fluid
            ></Image>
            Medicina Olistica
          </Col>
          <Col
            xs={6}
            className="p-2 text-center  justify-content-center  align-items-center link-hover"
            as={Link}
            to="/#trattamenti-tradizionali"
          >
            <Image
              src={props.dark ? tradizionaledark : tradizionalelight}
              fluid
            ></Image>
            Medicina Tradizionale
          </Col>
        </Row>
        <Row className=" justify-content-center mt-5 mb-2 align-items-center ref-container">
          <div id="trattamenti-olistici" className="ref-anchor"></div>
          <h2>Trattamenti olistici</h2>
        </Row>
        <>
          {Data["yin"].map((category, catKey) => {
            return (
              <Row
                key={catKey}
                as={Link}
                to={category.link}
                className="service-container mx-3"
              >
                <Col
                  xs={4}
                  className="my-2 justify-content-center d-flex flex-column"
                >
                  <Image
                    src={category.icon}
                    roundedCircle
                    fluid
                    className="w-100"
                  ></Image>
                </Col>
                <Col className="py-2 justify-content-center d-flex flex-column p-2">
                  <h3 className="text-center p-0 m-0">{category.name}</h3>
                </Col>
              </Row>
            );
          })}
        </>

        <Row className=" justify-content-center mt-4 mb-2 align-items-center ref-container">
          <div id="trattamenti-tradizionali" className="ref-anchor"></div>
          <h2>Trattamenti Tradizionali</h2>
        </Row>
        {Data["yang"].map((category, catKey) => {
          return (
            <Row
              key={catKey}
              as={Link}
              to={category.link}
              className="service-container mx-3"
            >
              <Col
                xs={4}
                className="my-2 justify-content-center d-flex flex-column"
              >
                <Image
                  src={category.icon}
                  roundedCircle
                  fluid
                  className="w-100"
                ></Image>
              </Col>
              <Col className="py-2 justify-content-center d-flex flex-column p-2">
                <h3 className="text-center p-0 m-0">{category.name}</h3>
              </Col>
            </Row>
          );
        })}

        <blockquote className="mt-5 blockquote text-center w-100">
          <h4>
            <cite>
              Sei stanco, stressato, senza energie e non trovi mai tempo per il
              tuo benessere?
              <br />
              Prenditi spazio per te!
              <br />
              Panta Rei è il luogo ideale per lasciarti coccolare.
            </cite>
          </h4>
          <footer className="w-100">
            <cite>Ti aspetto, Nicole</cite>
          </footer>
        </blockquote>
      </div>
    </>
  );
}

export default HomePageMobile;
