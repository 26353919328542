import React, { useState } from "react";
import { Col, Row, Image } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import Treatment from "../../components/Treatment";
import Data from "../../Data/Categories/MTC";
import DataArray from "../../Data/Data";
import { Helmet } from "react-helmet-async";
function MedicinaTradizionaleCinese({ footer = true }) {
  const quoteBig = (
    <p>
      <blockquote className="mt-4 blockquote text-center w-100">
        <h4>
          <cite>
            Non esiste riequilibrio energetico senza la presa di coscienza del
            sè individuale, senza la consapevolezza, ed è questo che ricerco in
            studio con i pazienti, perché tutto è collegato, tutto è UNO.
          </cite>
        </h4>
      </blockquote>
    </p>
  );
  const descriptionBig = (
    <>
      La Medicina Tradizionale Cinese: equilibrio di corpo, mente, spirito ed
      energia secondo l’unicità di ciascun individuo.<br></br>È una disciplina
      medica nata in oriente oltre 2000 anni fa che può essere considerata
      complementare alla medicina occidentale. La MTC è una medicina non
      convenzionale, che mira all’equilibrio dell’individuo, considerandolo come
      un essere unico "OLOS" in tutte le sue caratteristiche fisiche, mentali,
      emozionali ed energetiche. La medicina cinese è complessa ed affascinante
      ed utilizzando tecniche come la cromopuntura, la coppettazione, la
      moxibustione, l’auricoloterapia, le ginnastiche energetiche, la
      fitoterapia… si prefigge come unico scopo quello di utilizzare le energie
      stesse dell’individuo , stimolando l’intelligenza interna, per riportare
      il corpo in armonia sulla via dell’autoguarigione.
      {quoteBig}
    </>
  );

  const descriptionSmall = (
    <>
      La Medicina Tradizionale Cinese: equilibrio di corpo, mente, spirito ed
      energia secondo l’unicità di ciascun individuo.
    </>
  );

  //stato per gestire short or long text
  const [showMore, setShowMore] = useState(false);
  //gestore showMore/showLess
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Medicina Tradizionale Cinese - Studio Olistico Panta Rei</title>
        <meta
          name="description"
          content="Esplora i trattamenti della Medicina Tradizionale Cinese (MTC) presso lo Studio Olistico Panta Rei. La MTC, con oltre 2000 anni di storia, mira all'equilibrio di corpo, mente, spirito ed energia, considerando ogni individuo unico. Scopri trattamenti come la Valutazione Energetica, Cromopuntura, Coppettazione Cinese, Auricoloterapia, Moxibustione, Gua Sha Viso e Taping Energetico, che utilizzano antiche tecniche per stimolare l'intelligenza interna e favorire l'autoguarigione. Sperimenta il benessere attraverso la consapevolezza del Sé e la ricerca dell'armonia."
        />
        <meta
          name="keywords"
          content="Trattamenti MTC, Medicina Tradizionale Cinese, Valutazione Energetica, Cromopuntura, Coppettazione Cinese, Auricoloterapia, Moxibustione, Gua Sha Viso, Taping Energetico, Equilibrio, Armonia, Consapevolezza, Olistico, Panta Rei, Mtc, Kinesiologia, Coppettazione, moxa, Taping, Riflessologia e Dien Chan, Access Bars, Chakra, Fiori Bach e Australiani, Meditazione, Massaggi, Cassano d'Adda, Martesana, Milano,  Terapie Olistiche, Cassano"
        />
        <meta
          property="og:title"
          content="Medicina Tradizionale Cinese - Studio Olistico Panta Rei"
        />
        <meta
          property="og:description"
          content="Vieni a provare la Medicina Tradizionale Cinese presso lo Studio Olistico Panta Rei a Cassano D'Adda. Scopri trattamenti come Cromopuntura, Coppettazione Cinese, Auricoloterapia, Moxibustione, Gua Sha Viso e Taping Energetico. Approfondisci la valutazione energetica e la visione unica della MTC nel riportare corpo, mente e spirito in armonia."
          data-rh="true"
        />
      </Helmet>
      <Row className="justify-content-center align-items-center">
        <Row className="justify-content-center d-flex flex-column p-2 p-md-5">
          <h1 className="mt-3 text-center">
            <Image src={Data.icon} className="title-icon"></Image>
            {Data.name}
          </h1>
        </Row>
        <Col className="justify-content-center d-flex d-none d-md-block flex-column p-2 ">
          <p className="text-center text-md-start">{descriptionBig}</p>
        </Col>
        <Col className="justify-content-center d-flex d-md-none flex-column p-2 ">
          <p className="justify-content-center d-flex d-block d-sm-none flex-column p-2 ">
            {showMore ? descriptionBig : descriptionSmall}
            <Col
              className={"my-3 mx-auto d-block d-sm-none"}
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={handleShowMore}
            >
              {showMore ? "Mostra di meno" : "Mostra di più"}
            </Col>
          </p>
        </Col>
        <Row className="mx-2 text-center text-md-start">
          <></>
        </Row>
        {Data.treatments.map((treatment, id) => {
          return (
            <>
              <Treatment
                key={id}
                title={treatment.name}
                image={treatment.image}
                descriptionBig={treatment.description}
                descriptionSmall={treatment.descriptionSmall}
              />
            </>
          );
        })}
        <div className="mb-4 justify-content-center align-items-center d-flex flex-row">
          {DataArray.yin.map((cat, id) => {
            if (footer && cat.name != Data.name) {
              return (
                <Link key={id} to={cat.link} className="mx-2 big-scale-hover">
                  <Image className="image-icon" src={cat.icon} fluid></Image>
                </Link>
              );
            }
          })}
        </div>
      </Row>
    </>
  );
}

export default MedicinaTradizionaleCinese;
