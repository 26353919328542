import Category from "../Category";
import TreatmentObject from "../TreatmentObject";
import React from "react";

import icon from "../../images/treatmentsIcons/terapia-manuale.png";

const TerapiaManuale = new Category(
  "Terapia Manuale",
  [],
  "/trattamenti/terapie-tradizionali",
  icon
);

export default TerapiaManuale;
