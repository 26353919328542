import MTC from "./Categories/MTC";
import Riflessologia from "./Categories/Riflessologia";
import Massaggi from "./Categories/Massaggi";
import TecnicheDiRilassamento from "./Categories/TecnicheDiRilassamento";
import FloriterapiaeAromaterapia from "./Categories/FloriterapiaeAromaterapia";
import TapingEnergetico from "./Categories/TapingEnergetico";

import FisioterapiaeRiabilitazione from "./Categories/FisioterapiaERiabilitazione";
import TerapiaManuale from "./Categories/TerapiaManuale";
import TerapieFisiche from "./Categories/TerapieFisiche";
import Kinesiostaping from "./Categories/Kinesiostaping";

const yin = [
  MTC,
  Massaggi,
  Riflessologia,
  TecnicheDiRilassamento,
  FloriterapiaeAromaterapia,
  // TapingEnergetico,
];

const yang = [
  FisioterapiaeRiabilitazione,
  TerapiaManuale,
  TerapieFisiche,
  Kinesiostaping,
];

const full = [
  MTC,
  Massaggi,
  Riflessologia,
  TecnicheDiRilassamento,
  FloriterapiaeAromaterapia,
  TapingEnergetico,
  FisioterapiaeRiabilitazione,
  TerapiaManuale,
  TerapieFisiche,
  Kinesiostaping,
];

const Data = {};

Data["yin"] = yin;
Data["yang"] = yang;
Data["full"] = full;

export default Data;
