import Category from "../Category";
import TreatmentObject from "../TreatmentObject";
import React from "react";

import icon from "../../images/treatmentsIcons/massaggi.png";

import olisticoImage from "../../images/treatmentsImages/Massaggi/massaggio-aroma.jpg";
import vietnamitaImage from "../../images/treatmentsImages/Massaggi/massaggio-meridiani.jpg";
import govindanImage from "../../images/treatmentsImages/Massaggi/massaggio-govindan.jpg";
import bioenergeticoImage from "../../images/treatmentsImages/Massaggi/massaggio-bioenergetico.jpg";
import cristalloterapiaImage from "../../images/treatmentsImages/FloriterapiaAromaterapia/cristalloterapia.jpg";
import legnoterapiaImage from "../../images/treatmentsImages/Massaggi/massaggio-legnoterapia.jpg";
import thaiImage from "../../images/treatmentsImages/Massaggi/massaggio-thai.jpg";
import svedeseImage from "../../images/treatmentsImages/Massaggi/massaggio-svedese.jpg";
import cocoonImage from "../../images/treatmentsImages/Massaggi/massaggio-cocoon.jpg";

//AROMA MASSAGE
const olisticoDescriptionSmall = (
  <>
    Rilassante e distensivo oppure energico e tonificante, i profumi e le
    proprietà energetiche delle essenze faranno il resto.
  </>
);
const olisticoDescription = (
  /*È un trattamento che sfrutta le proprietà energetiche delle
    essenze, permetterndo al corpo di attivare i sistemi di autoregolazione
    (equilibrio omeostatico) ottenendo uno stato di benessere generale. A
    seconda delle necessità il massaggio può essere dolce oppure intenso. In
    base al tipo di trattamento viene scelto un olio essenziale più adatto alla
    persona in quel momento.*/
  <>
    {olisticoDescriptionSmall}
    <br></br>È un trattamento che lavora tramite il canale olfattivo delle Acque
    del Benessere di ZAILO e quello cutaneo degli olii essenziali ideali per
    ognuno di noi, sfruttando le proprietà vibrazionali delle essenze utilizzate
    100% bio.
  </>
);

const olistico = new TreatmentObject(
  "Aroma Massage con olii essenziali bio 100% ed Acque del Benessere",
  olisticoDescription,
  olisticoImage,
  [],
  olisticoDescriptionSmall
);

//MASSAGGIO VIETNAMITA DEI MERIDIANI
const vietnamitaDescriptionSmall = (
  <>
    Sfioramenti leggeri con pennelli, carezze lungo i canali energetici con il
    talco, abbandono delle tensioni e rilascio emozionale in questa tecnica che
    favorisce il sonno.
  </>
);
const vietnamitaDescription = (
  <>
    {vietnamitaDescriptionSmall}
    <br></br>È anche chiamata sleeping therapy, spesso chi la prova si
    addormenta durante il trattamento. Attraverso una manualità leggera, fatta
    di sfioramenti lungo i meridiani di medicina cinese, si favorisce la
    circolazione di energia (Qi), di sangue e di liquidi, col conseguente
    scarico di tensioni, tossine e liberazione emotiva profonda. Il talco ed i
    pennelli agevolano il piacere del trattamento e l’abbandono dalle pressioni
    giornaliere.
  </>
);

const vietnamita = new TreatmentObject(
  "Massaggio Vietnamita dei meridiani",
  vietnamitaDescription,
  vietnamitaImage,
  [],
  vietnamitaDescriptionSmall
);

//MASSAGGIO ENERGETICO SPIRITUALE GOVINDAN
const govindanDescriptionSmall = (
  <>
    Suoni mantrici che cullano, profumo di candele ed incensi che avvolgono,
    durante il trattamento dei punti riflessi dei 7 chakra sotto i piedi.
    Qualche posizione yoga insieme prima di iniziare per chi lo desidera.
  </>
);
const govindanDescription = (
  <>
    {govindanDescriptionSmall} <br></br>
    Il Massaggio Energetico Spirituale Govindan (MES) è una tecnica ideata dal
    Maestro indiano Sri Govindan, che trae ispirazione dalla riflessologia
    plantare, dalla medicina tradizionale cinese e dall’Ayurveda. Il MES agisce
    sui punti riflessi dei 7 chakra che si trovano sotto la pianta dei piedi.
    Questi punti vengono stimolati con sfioramenti delicati, seguendo una
    sequenza ben precisa e sono accompagnati da suoni mantrici, detti "Bija
    Mantra" che accentuano l’effetto energetico del massaggio entrando in una
    sequenza vibratoria molto elevata, riuscendo a riequilibrare non solo i
    chakra, ma anche gli organi corrispondenti fino ad arrivare alle funzioni
    cerebrali. Trovo efficace, per chi lo desidera, effettuare alcune posizioni
    di yoga, scelte dal paziente prima del massaggio, per attivare la
    circolazione energetica ed ottenere il miglior risultato possibile a fine
    seduta.
  </>
);

const govindan = new TreatmentObject(
  "Massaggio Energetico dei Chakra",
  govindanDescription,
  govindanImage,
  [],
  govindanDescriptionSmall
);

//MASSAGGIO BIOENERGETICO
const bioernegeticoDescriptionSmall = (
  <>
    Pressioni e manualità specifiche in base alla tipologia fisica di ciascuno,
    sbloccano tensioni, migliorano la tranquillità emotiva, rinforzano muscoli
    ed articolazioni.
  </>
);
const bioenergeticoDescription = (
  <>
    {bioernegeticoDescriptionSmall}
    <br></br>
    Se l'energia fluisce, il corpo è forte, vitale e pulsante. Quando è bloccata
    o trattenuta e ristagna diventa distruttiva e provoca disfunzioni e degenera
    in malattia. La bioenergetica è una tecnica corporea che "legge il corpo", i
    suoi movimenti, i suoi atteggiamenti, che possono svelare punti di blocco.
    Gli oli essenziali applicati nelle 5 aree energetiche, tramite massaggio,
    possono aiutare a ritrovare armonia a livello emotivo e fisico.
  </>
);

//CRISTALLOTERAPIA
const cristalloterapiaDescriptionSmall = (
  <>
    I cristalli e le Acque del Benessere di Zailo si fondono in questo
    trattamento che lavora sulle energie sottili, sulla sfera vibrazionale, sul
    nostro sentire più universale.
  </>
);
const cristalloterapiaDescription = (
  <>
    {cristalloterapiaDescriptionSmall} <br></br>L’utilizzo dei cristalli più
    adatti alla persona in quel momento e la scelta dell’acqua del benessere
    perfetta in abbinamento al trattamento sono la chiave per rafforzare
    l’autoconsapevolezza e la risintonizzazione con sé stessi e le proprie
    energie. I cristalli sono compagni minerali che accompagnano l’uomo fin
    dalle antiche civiltà ed il loro potere vibrazionale è indiscutibile. Le
    Acque del Benessere sono miscele spray che contengono ciascuna dai 7 ai 12
    olii essenziali puri e certificati che agiscono sulle emozioni e sul campo
    energetico vitale
  </>
);

const cristalloterapia = new TreatmentObject(
  "Cristalloterapia",
  cristalloterapiaDescription,
  cristalloterapiaImage,
  [],
  cristalloterapiaDescriptionSmall
);

const bioenergetico = new TreatmentObject(
  "Massaggio Bioenergetico",
  bioenergeticoDescription,
  bioenergeticoImage,
  [],
  bioernegeticoDescriptionSmall
);

//LEGNOTERAPIA
const legnoterapiaDescriptionSmall = (
  <>
    L’utilizzo degli strumenti in legno di bamboo incontra la spazzolatura a
    secco in setole naturali per creare un rituale caro a Cleopatra.
  </>
);
const legnoterapiaDescription = (
  <>
    {legnoterapiaDescriptionSmall}
    <br></br>È una tecnica di massaggio che ha come obiettivo la RIGENERAZIONE
    ed il RIEQUILIBRIO dell’energia vitale QI, abbinando la spazzolatura a secco
    alle tecniche manuali ed all’utilizzo di strumenti di legno di diverse
    dimensioni e forme. Gli effetti che si ottengono sono una piacevole
    esfoliazione e rassodamento della pelle con miglioramento della produzione
    di elastina, vitamina E, collagene oltre che riduzione dello stress,
    miglioramento della circolazione vascolare e linfatica e diminuzione dei
    dolori muscolari ed articolari.
  </>
);

const legnoterapia = new TreatmentObject(
  "Dry Brushing-Legnoterapia-Bamboo Massage",
  legnoterapiaDescription,
  legnoterapiaImage,
  [],
  legnoterapiaDescriptionSmall
);
//SVEDESE
const svedeseDescriptionSmall = (
  <>
    Manualità caratterizzata dall’esecuzione di sfioramenti, sfregamenti,
    impastamenti, frizioni, percussioni di intensità più o meno elevata, con
    olio caldo alternato a gel freddo per creare effetto shock termico.
  </>
);
const svedeseDescription = (
  <>
    {svedeseDescriptionSmall}
    <br></br>È un particolare tipo di massaggio occidentale nato in Europa, che
    grazie all’abbinamento del caldo/freddo, crea lo shock termico necessario al
    sistema per “mollare le redini” lasciandosi andare e creare effetto
    rilassante e tonificante.
  </>
);

const svedese = new TreatmentObject(
  "Massaggio Svedese",
  svedeseDescription,
  svedeseImage,
  [],
  svedeseDescriptionSmall
);
//THAI MASSAGE
const thaiDescriptionSmall = (
  <>
    È un massaggio che si svolge a terra su un tappetino e con abiti comodi, si
    basa sull’utilizzo di compressioni, distrazioni, stretching combinati,
    utilizzando la pressione delle mani, dei gomiti, delle ginocchia e dei
    piedi, in modo intenso e profondo.
  </>
);
const thaiDescription = (
  <>
    {thaiDescriptionSmall}
    <br></br>Questa tecnica nasce dai monaci buddisti in Thailandia circa 2500
    anni fa e combina la conoscenza dei punti di digitopressione della medicina
    cinese con uno stretching assistito ispirato allo Yoga di derivazione
    indiana. L’obiettivo è liberare il corpo dal “traffico” che non permette il
    libero fluire delle energie, con il vantaggio di ridurre i livelli di
    cortisolo favorendo buona energia globale ed armonia psicofisica.
  </>
);

const thai = new TreatmentObject(
  "Thai Massage (a terra)",
  thaiDescription,
  thaiImage,
  [],
  thaiDescriptionSmall
);
//RITUALE COCOON
const cocoonDescriptionSmall = (
  <>
    Lascia che il tuo corpo fluttui nell’aria, COCOON è un vero e proprio rito,
    un momento di totale abbandono, una coccola dalla testa ai piedi.
  </>
);
const cocoonDescription = (
  <>
    {cocoonDescriptionSmall}
    <br></br>Un delicato scrub con sale dell’Himalaya, gli sfioramenti con un
    panno caldo, il profumo del burro di Karité con vitamina E e degli olii
    essenziali utilizzati, ti trasporteranno in un’altra dimensione. Un
    massaggio dai capelli alle dita dei piedi, avvolgente, estremamente
    rilassante e nutriente che libera la mente e fa scorrere il tempo
    lentamente.
  </>
);

const cocoon = new TreatmentObject(
  "Rituale Cocoon al Burro di Karité",
  cocoonDescription,
  cocoonImage,
  [],
  cocoonDescriptionSmall
);
//const cromopunturaReal= new TreatmentObject("cromopuntura",  cromopunturaDescription, cromopunturaImage, [cromopunturaImage2, cromopunturaImage3]);

const Massaggi = new Category(
  "Massaggi Energetico-Vibrazionali",
  [],
  "/trattamenti/massaggi",
  icon
);
Massaggi.addElement(olistico);
Massaggi.addElement(bioenergetico);
Massaggi.addElement(cristalloterapia);
Massaggi.addElement(vietnamita);
Massaggi.addElement(govindan);
Massaggi.addElement(svedese);
Massaggi.addElement(legnoterapia);
Massaggi.addElement(thai);
Massaggi.addElement(cocoon);
export default Massaggi;
