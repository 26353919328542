export const PantaReiAddress = "Via vaprio 60, 20062 Cassano D’Adda (MI)";
export const PantaReiMapsLink = "https://goo.gl/maps/pujLe34ZccXBuahe9";

export const PantaReiTel = "3397617704";
export const PantaReiTelLink = "tel:" + PantaReiTel.replace(/\s/g, "");

export const PantaReiMail = "info@olisticopantarei.it";
export const PantaReiMailLink = "mailto:" + PantaReiMail;

export const PantaReiWhatsapp = "3397617704";
export const PantaReiWhatsappLink =
  "https://wa.me/" + PantaReiWhatsapp.replace(/\s/g, "");

export const PantaReiInstagramLink =
  "https://www.instagram.com/olisticopantarei/";
export const PantaReiFacebookLink =
  "https://www.facebook.com/profile.php?id=100090715335589";
export const PantaReiLinkedinLink =
  "https://www.linkedin.com/in/nicole-cristine-rainer-442149263/";

export const PantaReiPIVA = "01422500197";

export const CarioniAddress =
  "Via Gen. C.A. dalla Chiesa 20, 20062 Cassano D’Adda (MI)";
export const CarioniMapsLink =
  "https://maps.google.com/maps?ll=45.529198,9.511559&z=16&t=m&hl=it&gl=IT&mapclient=embed&cid=10874439866659605907";

export const CarioniTel = "0363 60042";
export const CarioniTelLink = "tel:" + CarioniTel.replace(/\s/g, "");

export const CarioniWhatsapp = "3476071735";
export const CarioniWhatsappLink =
  "https://wa.me/" + CarioniWhatsapp.replace(/\s/g, "");

export const CarioniMail = "centro@fisioterapiacarioni.it";
export const CarioniMailLink = "mailto:" + CarioniMail;
export const CarioniSiteLink = "http://www.fisioterapiacarioni.it/";
