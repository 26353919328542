import React, { useState } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import nicole from "../images/ChiSono/NicoleNew.jpg";
import NicoleMare from "../images/ChiSono/NicoleMare.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CV from "../CV/CvRainerNicole.pdf";
import { Helmet } from "react-helmet-async";
function ChiSono() {
  //stato per gestire short or long text
  const [showMore, setShowMore] = useState(false);
  //gestore showMore/showLess
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  const teaserText = (
    <>
      <p>
        Sono Nicole Rainer, fisioterapista, terapista manuale osteopatica,
        idrokinesiterapista, terapista della mano ed operatrice olistica e
        vorrei aiutarti a diventare la versione migliore di te.
      </p>
      <p>
        La mia filosofia crede nell’unicità dell’individuo e nelle sue
        potenzialità, dove il sintomo spesso è la manifestazione di qualcosa di
        piu’ profondo che va ricercato, compreso e ri-equilibrato, stimolando
        l’intelligenza interna ad ognuno di noi.
      </p>
      <p>
        Le terapie che ho scelto di utilizzare sono gli strumenti per arrivare
        alla radice di un disagio. Ciascuna tecnica, unitamente alla voglia di
        ciascuno di mettersi in discussione e migliorare, porta al benessere ed
        alla consapevolezza del sè.
      </p>
      <Col
        className={"my-3 mx-auto d-block d-sm-none"}
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={handleShowMore}
      >
        Mostra di più
      </Col>
    </>
  );

  const teaserBlockquote = (
    <blockquote className="blockquote text-center w-100">
      <footer className="w-100">
        <h2>
          <cite>Vieni a trovarmi</cite> <br></br>
          <cite>Ti aspetto, Nicole</cite>
        </h2>
      </footer>
    </blockquote>
  );

  const fullText = (
    <>
      <p>
        Sono Nicole Rainer, fisioterapista, terapista manuale osteopatica,
        idrokinesiterapista, terapista della mano, operatrice olistica e vorrei
        aiutarti a diventare la versione migliore di te
      </p>
      <p>
        Da più di 15 anni lavoro in ambito riabilitativo privato, sia in studio
        che a domicilio, con consulenze in palestra e trattamenti individuali in
        piscina. Da diversi anni ormai ho sviluppato interesse alle discipline
        complementari, medicina cinese, coppettazione, riflessologia, tecniche
        di rilassamento, medicina ayurvedica… e continuo con curiosità ed
        interesse a coltivarle.
      </p>
      <p>
        La mia filosofia crede nell’unicità dell’individuo e nelle sue
        potenzialità, dove il sintomo spesso è la manifestazione di qualcosa di
        più profondo che va ricercato, compreso e re-equilibrato, stimolando
        l’intelligenza interna ad ognuno di noi. Le terapie che ho scelto di
        utilizzare sono gli strumenti per arrivare alla radice di un disagio
        unite alla voglia di ciascuno di mettersi in discussione, cercando
        insieme la strada del benessere e della consapevolezza del SE.
      </p>
      <p>
        Il mio obiettivo con <strong>PANTA REI</strong> è di integrare la
        visione occidentale a quella orientale in una visione olistica integrata
        dell’uomo come unione di corpo, mente, spirito ed energia per vivere in
        salute e in armonia con ciò che ci circonda.
      </p>
      <p>
        L’augurio e la speranza per me e per voi sono di crescere insieme, con
        il coraggio di sondare anche le nostre “zone buie”, la volontà e la
        determinazione di attuare un cambiamento ove indispensabile, la pazienza
        di regalarsi il tempo necessario per farlo e la gioia di condividere
        anche le piccole vittorie.
      </p>
      <p>
        Credo che nulla accada per caso, ma che accada quando è il momento
        giusto per noi per riuscire a comprenderlo ed accettarlo facendolo
        nostro davvero.
      </p>
      <p>
        <a
          href={CV}
          target="_blank"
          className="text-decoration-none link-bold-hover"
        >
          Clicca qui per vedere il mio CV nei dettagli{" "}
          <FontAwesomeIcon icon={solid("infinity")} size="2xl" />
        </a>
      </p>
      <Col
        className={"my-3 mx-auto d-block d-sm-none"}
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={handleShowMore}
      >
        Mostra di meno
      </Col>
    </>
  );

  const fullBlockquote = (
    <blockquote className="blockquote text-center w-100">
      <h3>
        <cite>
          È questo il tuo momento per stare meglio?
          <br /> Prenditi del tempo per te e vieni a trovarmi. <br />
          Troverai un ambiente immerso nella natura e nel silenzio,
          <br /> dove sceglieremo insieme il percorso ideale per la tua salute.
          <br />
        </cite>
      </h3>
      <footer className="w-100">
        <cite>Ti aspetto, Nicole</cite>
      </footer>
    </blockquote>
  );

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Chi sono - Studio Olistico Panta Rei</title>
        <meta
          property="og:title"
          content="Chi sono - Studio Olistico Panta Rei"
        />
        <meta
          name="description"
          content="Sono Nicole Rainer, fisioterapista, terapista manuale osteopatica, idrokinesiterapista e operatrice olistica con oltre 15 anni di esperienza nel settore riabilitativo. Specializzata in medicina cinese, coppettazione, riflessologia e medicina ayurvedica, lavoro per aiutarti a diventare la tua versione migliore. Scopri la mia visione olistica integrata dell'uomo, unendo la prospettiva occidentale a quella orientale, per vivere in salute e armonia. Contatta PANTA REI per un percorso di benessere personale."
        />
        <meta
          name="keywords"
          content="Nicole Rainer, Fisioterapista, Terapista Manuale Osteopatica, Idrokinesiterapista, Terapista della Mano, Operatrice Olistica, Medicina Cinese, Coppettazione, Riflessologia, Tecniche di Rilassamento, Medicina Ayurvedica, Benessere, Olistico, PANTA REI, Cassano d'Adda, Studio Olistico, Mtc, Kinesiologia, moxa, Moxibustione, Taping, Riflessologia e Dien Chan, Access Bars, Chakra, Fiori Bach e Australiani, Meditazione, Medicina Tradizionale Cinese, Massaggi, Martesana, Milano,  Terapie Olistiche, Cassano"
        />
        <meta
          property="og:description"
          content="Nicole Rainer, fisioterapista e terapista manuale osteopatica, integra il benessere olistico con trattamenti riabilitativi a Cassano D'Adda. 
          Con oltre 15 anni di esperienza, unisce la saggezza della medicina tradizionale a discipline olistiche come medicina cinese, riflessologia e rilassamento. 
          Scopri il percorso olistico ideale per la tua salute presso lo Studio Panta Rei"
          data-rh="true"
        />
      </Helmet>
      <Row className="mx-2 justify-content-center">
        <Col xs={12} lg={4} className="justify-content-center">
          <Col className="justify-content-center d-flex d-block d-md-none flex-column p-2 p-md-5">
            <h1 className="mt-3 text-center text-md-start">Chi Sono</h1>
          </Col>
          <Image src={NicoleMare} fluid rounded></Image>
        </Col>
        <Col className="justify-content-center d-flex d-block d-sm-none flex-column p-2 p-md-5">
          {showMore ? fullText : teaserText}
        </Col>
        <Row className="text-center mb-3  justify-content-center d-block d-sm-none">
          {showMore ? fullBlockquote : teaserBlockquote}
        </Row>
        <Col lg={8} className="d-none d-md-block mb-3">
          <h1 className="mt-3 mt-lg-0 ps-lg-4 d-none d-md-block">Chi Sono</h1>
          {fullText}
        </Col>
        <Row className="text-center mb-3  justify-content-center d-none d-sm-block">
          {fullBlockquote}
        </Row>
      </Row>
    </>
  );
}

export default ChiSono;
