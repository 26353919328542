import Category from "../Category";
import TreatmentObject from "../TreatmentObject";
import React from "react";

import icon from "../../images/treatmentsIcons/riflessologia.png";
import dienChanImage from "../../images/treatmentsImages/Riflessologia/riflessologia-viso.jpg";
import riflessologiaManoImage from "../../images/treatmentsImages/Riflessologia/riflessologia-mano.jpg";
import accessBarsImage from "../../images/treatmentsImages/Riflessologia/accessBars.jpg";
import riflessologiaPiedeImage from "../../images/treatmentsImages/Riflessologia/riflessologia-piede.jpg";
import riflessologiaTotaleImage from "../../images/treatmentsImages/Riflessologia/riflessologia-totale.jpg";
//ACCESS BARS
const accessBarsDescriptionSmall = (
  <>
    I BARS® sono 32 punti situati sulla testa che toccati delicatamente
    stimolano un cambiamento positivo nel cervello e destrutturano le componenti
    elettromagnetiche dello stress, dei pensieri e delle emozioni.
  </>
);
const accessBarsDescription = (
  <>
    {accessBarsDescriptionSmall}
    <br></br>È una tecnica dolce che agisce sul rilascio dei blocchi fisici e
    mentali racchiusi nel corpo ed aiuta a promuovere una maggior facilità in
    tutti i vari aspetti della vita. Quando fai scorrere i BARS® stai
    letteralmente modificando il tuo spazio e ti stai aprendo alle infinite
    possibilità, stai cliccando il tasto “reset” sul computer.
  </>
);
const accessBars = new TreatmentObject(
  "Access Bars",
  accessBarsDescription,
  accessBarsImage,
  [],
  accessBarsDescriptionSmall
);
//RIFLESSOLOGIA PIEDE
const riflessologiaPiedeDescriptionSmall = (
  <>
    Il massaggio energetico dei chakra sul piede incontra le zone riflesse
    legate alle nostre emozioni presenti sulle dita creando il connubio
    perfetto.
  </>
);
const riflessologiaPiedeDescription = (
  <>
    {riflessologiaPiedeDescriptionSmall}
    <br></br>Due splendide tecniche per il riequilibrio energetico dei nostri
    punti vitali. Non punti riflessi di organi e visceri come vuole la
    tradizione ma zone riflesse legate al nostro sentire più profondo, a ciò che
    siamo e che non sempre riusciamo a liberare, le nostre emozioni.
  </>
);
const riflessologiaPiede = new TreatmentObject(
  "Riflessologia del piede e MES",
  riflessologiaPiedeDescription,
  riflessologiaPiedeImage,
  [],
  riflessologiaPiedeDescriptionSmall
);
//RIFLESSOLOGIA FACCIALE VIETNAMITA DIEN CHAN
const dienChanDescriptionSmall = (
  <>
    Dal Vietnam una tecnica semplice ed immediata per il nostro benessere. Lo
    stimolo di punti e mappe riflesse del nostro corpo sul viso, diventa un
    autotrattamento nella routine di salute quotidiana.
  </>
);
const dienChanDescription = (
  <>
    {dienChanDescriptionSmall}
    <br></br>È una disciplina nata durante la guerra del Vietnam, dal Maestro
    Bui Quoc Chau. Il Dien Chan si basa sul trattamento di punti e zone riflesse
    del viso secondo mappe che riflettono tutto il corpo, con sfioramenti,
    pressioni, picchiettamenti, frizioni, effettuate con strumenti di diversa
    forma e funzione. Il trattamento è spesso preceduto dalla detersione e
    riscaldamento del viso con un panno di cotone caldo che prepara alla
    ricezione del trattamento nel migliore dei modi. La tecnica apporta
    giovamento, spesso anche immediato, al sintomo ed alla funzionalità di
    organi ed apparati di tutto il corpo. Apprendere la tecnica è facile, così
    da ricrearla anche a casa per la propria routine di benessere quotidiano.
  </>
);

const dienChan = new TreatmentObject(
  "Riflessologia facciale Dien Chan",
  dienChanDescription,
  dienChanImage,
  [],
  dienChanDescriptionSmall
);

//RIFLESSOLOGIA DELLA MANO
const riflessologiaManoDescriptionSmall = (
  <>
    Pressioni, sfioramenti, massaggi delle mani secondo le mappe dei punti
    riflessi degli organi, stimola la reazione sugli organi stessi per sbloccare
    il loro stato di cattivo funzionamento.
  </>
);
const riflessologiaManoDescription = (
  <>
    {riflessologiaManoDescriptionSmall}
    <br></br>
    Tecnica probabilmente conosciuta già 5000 anni fa, sia in India che in Cina.
    Palmo, dorso e dita sono come i piedi per la riflessologia plantare, una
    mappa completa di tutto il corpo umano. La riflessologia delle mani insegna
    che massaggiando in un certo modo i punti riflessi che corrispondono ai vari
    organi, si interviene sugli organi stessi stimolandoli a reagire ed a
    sbloccare la ripresa della funzionalità.
  </>
);

const riflessologiaMano = new TreatmentObject(
  "Riflessologia della mano",
  riflessologiaManoDescription,
  riflessologiaManoImage,
  [],
  riflessologiaManoDescriptionSmall
);
//RIFLESSOLOGIA TOTALE(TESTA-VISO-MANI-PIEDI)
const riflessologiaTotaleDescriptionSmall = (
  <>
    Un trattamento riflessologico globale, di tutti i punti sulle 4 parti del
    corpo per potenziare gli stimoli e gli effetti.
  </>
);
const riflessologiaTotaleDescription = (
  <>
    {riflessologiaTotaleDescriptionSmall}
    <br></br>
    Tutta la riflessologia che ho in questo trattamento a 360°. Tante mappe per
    tanti disagi, tante zone per una grande variabilità di trattamenti. È il tuo
    corpo che racconta cosa vuole, lo ascolteremo e realizzeremo i suoi
    desideri.
  </>
);

const riflessologiaTotale = new TreatmentObject(
  "Riflessologia Testa-Viso-Mani-Piedi",
  riflessologiaTotaleDescription,
  riflessologiaTotaleImage,
  [],
  riflessologiaTotaleDescriptionSmall
);

const Riflessologia = new Category(
  "Riflessologia ed Access Bars",
  [],
  "/trattamenti/riflessologia",
  icon
);
Riflessologia.addElement(accessBars);
Riflessologia.addElement(dienChan);
Riflessologia.addElement(riflessologiaMano);
Riflessologia.addElement(riflessologiaPiede);
Riflessologia.addElement(riflessologiaTotale);

export default Riflessologia;
