import Category from "../Category";
import TreatmentObject from "../TreatmentObject";
import React from "react";

import icon from "../../images/treatmentsIcons/floriterapia-etc.png";
import floriterapiaImage from "../../images/treatmentsImages/FloriterapiaAromaterapia/fiorivari.jpg";
import aromaterapiaImage from "../../images/treatmentsImages/FloriterapiaAromaterapia/aromaterapia.jpg";
import kinesiologiaImage from "../../images/treatmentsImages/FloriterapiaAromaterapia/kinesiologia.jpg";

//KINESIOLOGIA
const kinesiologiaDescriptionSmall = (
  <>
    Tramite il test muscolare della ruota dei meridiani valutiamo gli squilibri
    che il corpo manifesta e testiamo di cosa ha bisogno per ritrovare
    equilibrio.
  </>
);
const kinesiologiaDescription = (
  <>
    {kinesiologiaDescriptionSmall}
    <br></br>
    L’evoluzione della Kinesiologia Applicata di Goodheart degli anni ’60 vieni
    utilizzata come kinesiologia sistematica. Il corpo umano viene rappresentato
    da un triangolo equilatero, dove ogni lato rappresenta un aspetto del corpo
    su cui è possibile intervenire: la parte fisica-strutturale, la parte
    biochimico-nutrizionale, la parte emotiva, con al centro quella energetica.
    Attraverso le tecniche della kinesiologia valutiamo carenze ed eccessi e
    cerchiamo il modo migliore di correggerli.
  </>
);

const kinesiologia = new TreatmentObject(
  "Kinesiologia",
  kinesiologiaDescription,
  kinesiologiaImage,
  [],
  kinesiologiaDescriptionSmall
);
//FLORITERAPIA= bach+australiani+fitoterapia
const floriterapiaDescriptionSmall = (
  <>
    Sono estratti di fiori e piante medicinali che aiutano a migliorare il
    benessere interiore ed emotivo. Il test kinesiologico ci aiuterà a scegliere
    qual è l'estratto più adatto al tuo disagio.
  </>
);
const floriterapiaDescription = (
  <>
    {floriterapiaDescriptionSmall}
    <br></br>
    Siamo ormai orientati al pensiero che la cura del dolore fisico possa
    partire anche da una terapia sul disagio psicologico ed i fiori guaritori,
    rimedi semplici e naturali e totalmente privi di effetti collaterali, agendo
    a livello vibrazionale, ci curano in profondità. Negli estratti filtrati
    rimane presente la "vibrazione energetica" del fiore che entrando in
    risonanza con noi, ci aiuta a ritrovare l'equilibrio interiore e a
    trasformare le emozioni e gli stati d’animo bloccati, stimolando le nostre
    capacità di auto-guarigione e di auto- affermazione.
  </>
);

const floriterapia = new TreatmentObject(
  "Fiori di Bach, Fiori Australiani, Fitoterapia",
  floriterapiaDescription,
  floriterapiaImage,
  [],
  floriterapiaDescriptionSmall
);

//AROMATERAPIA
const aromaterapiaDescriptionSmall = (
  <>
    Oli essenziali che agiscono sul corpo e sulla psiche di tutti noi, diventano
    un valido alleato ad ogni trattamento per potenziarne gli effetti sulla
    sfera emotiva ed energetica.
  </>
);
const aromaterapiaDescription = (
  <>
    {aromaterapiaDescriptionSmall} <br></br>È considerata una "terapia dolce"
    che unisce il piacere all'utilità realizzando interventi di provata
    efficacia. L'aromaterapia utilizza oli essenziali, sostanze volatili e
    odorose contenute nei diversi organi delle piante (semi, radici, foglie,
    fiori, frutti). Ognuno di noi è sensibile agli odori ed ai profumi e la
    risposta che ne deriva è immediata ed incontrollabile. Gli oli essenziali,
    che comunemente vengono estratti tramite distillazione in corrente di vapore
    sono strumenti efficaci che, adeguatamente scelti, agiscono piacevolmente
    sul corpo e sulla psiche di tutto noi. L'aromaterapia può essere un valido
    coadiuvante ad ogni trattamento e potenziarne gli effetti anche sulla sfera
    emotiva.
  </>
);

const aromaterapia = new TreatmentObject(
  "Aromaterapia",
  aromaterapiaDescription,
  aromaterapiaImage,
  [],
  aromaterapiaDescriptionSmall
);

const FloriterapiaeAromaterapia = new Category(
  "Floriterapia e Aromaterapia",
  [],
  "/trattamenti/fitoterapia-e-aromaterapia",
  icon
);
FloriterapiaeAromaterapia.addElement(kinesiologia);
FloriterapiaeAromaterapia.addElement(floriterapia);
FloriterapiaeAromaterapia.addElement(aromaterapia);
//FloriterapiaeAromaterapia.addElement(taping);

export default FloriterapiaeAromaterapia;
