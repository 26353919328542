import React from "react";
//import HomepageDesktop from "./HomepageDesktop";
import HomepageDesktop from "./HomepageDesktop3";
import HomePageMobile from "./HomepageMobile";

function Homepage(props) {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 992);
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  return isMobile ? (
    <HomePageMobile dark={props.dark} />
  ) : (
    <HomepageDesktop dark={props.dark} />
  );
}

export default Homepage;
