import imageTest from "../images/template/template-square.png";

class TreatmentObject {
  constructor(name, description = "", image = imageTest, otherImages = [], descriptionSmall="") {
    this.name = name;
    this.image = image;
    this.descriptionSmall= descriptionSmall
    this.description = description;
    this.otherImages = otherImages;
  }
}

export default TreatmentObject;
