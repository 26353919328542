import Category from "../Category";
import TreatmentObject from "../TreatmentObject";
import React from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import icon from "../../images/treatmentsIcons/mtc.png";

import cromopunturaImage from "../../images/treatmentsImages/MTC/cromoterapia.jpg";
import coppettazioneImage from "../../images/treatmentsImages/MTC/coppettazione.jpg";
import moxibustioneImage from "../../images/treatmentsImages/MTC/moxibustione.jpg";
import auricoloterapiaImage from "../../images/treatmentsImages/MTC/auricoloterapia.jpg";
import tapingImage from "../../images/treatmentsImages/MTC/taping-energetico.jpg";
import guashaImage from "../../images/treatmentsImages/MTC/guasha.jpg";
import valutazioneImage from "../../images/treatmentsImages/MTC/valutazione.jpg";

//MTCDESCRIPTION DEPRECATED
const MTCDescription = (
  <>
    <br></br>
    La Medicina Tradizionale Cinese è una disciplina medica nata in oriente
    oltre 2000 anni fa che può essere considerata complementare alla medicina
    occidentale. La MTC è una medicina non convenzionale, che mira
    all’equilibrio dell’individuo, considerandolo come un essere unico “OLOS”
    (chiedere acronimo) in tutte le sue caratteristiche fisiche, mentali,
    emozionali ed energetiche. È una disciplina complessa ed affascinante, che
    tramite varie tecniche come la cromopuntura, la coppettazione, il
    moxibustione, l’auricoloterapia, le ginnastiche energetiche, la fitoterapia…
    si prefigge come unico scopo quello di utilizzare le energie stesse della
    persona, stimolando l’intelligenza interna, per riportare il corpo in
    armonia sulla via dell’autoguarigione. Non esiste riequilibrio energetico
    senza la presa di coscienza del se individuale, senza la consapevolezza, ed
    è questo che ricerco in studio con i pazienti, perché tutto è collegato,
    tutto è UNO.
  </>
);
//VALUTAZIONE ENERGETICA
const valutazioneDescriptionSmall = (
  <>
    La valutazione energetica è la modalità di indagine dei disequilibri
    dell’energia vitale, il modo per indagare quanto le nostre batterie sono
    scariche, dove lo sono di più, e come fare la ricarica veloce.
  </>
);
const valutazioneDescription = (
  <>
    {valutazioneDescriptionSmall}
    <br></br>
    Durante l’indagine si possono utilizzare diverse tecniche e strumenti, dalla
    medicina cinese, alla kinesiologia, dal reiki, alla radiestesia, dal
    magnetismo, dalle richieste verbali, alla canalizzazione… Tutte hanno in
    comune l’ascolto e la comprensione delle disarmonie del nostro sistema e la
    scelta di come ribilanciarle al meglio.
  </>
);

const valutazione = new TreatmentObject(
  "Valutazione Energetica",
  valutazioneDescription,
  valutazioneImage,
  [],
  valutazioneDescriptionSmall
);
//CROMOPUNTURA
const cromopunturaDescriptionSmall = (
  <>
    Agopuntura senza aghi, utilizzo di luce ed colori per riequilibrare
    l’organismo nella sua globalità.
  </>
);
const cromopunturaDescription = (
  <>
    {cromopunturaDescriptionSmall}
    <br></br>
    Integrando il test kinesiologico, l'osservazione della lingua, l'ascolto dei
    polsi e l'anamnesi del paziente si trattano con la penna Cromomed i disturbi
    emersi per migliorare i sintomi ed arrivare alla causa del disagio.
  </>
);

const cromopuntura = new TreatmentObject(
  "Cromopuntura",
  cromopunturaDescription,
  cromopunturaImage,
  [],
  cromopunturaDescriptionSmall
);

//COPPETTAZIONE
const coppettazioneDescriptionSmall = (
  <>
    Vasi di vetro sulla cute stimolano miglioramento del dolore, azione
    decontratturante, diminuzione dell'infiammazione e miglioramento del flusso
    sanguigno.
  </>
);
const coppettazioneDescription = (
  <>
    {coppettazioneDescriptionSmall}
    <br></br>È una terapia che grazie all'azione di trazione e suzione sui
    tessuti sottostanti allevia il dolore perché produce intensa azione
    decongestionante e rilassante, crea ossigenazione tissutale, diminuzione
    dell’infiammazione ed aumento della velocità del processo di guarigione. La
    cupping therapy è spesso associata ad un massaggio pre ed uno post che
    preparano e concludono l'utilizzo delle coppette. I punti stimolati nella
    coppettazione sono specifici per il disagio dell’individuo e seguono la
    logica 5 elementi e Yin/Yang della medicina cinese.
  </>
);

const coppettazione = new TreatmentObject(
  "Coppettazione Cinese",
  coppettazioneDescription,
  coppettazioneImage,
  [],
  coppettazioneDescriptionSmall
);
//AURICOLOTERAPIA
const auricolotepiaDescriptionSmall = (
  <>
    Si stimolano nell'orecchio, con i semi di vaccaria, i punti riflessi
    corrispondenti a tutto il corpo, producendo effetti benefici sull’intero
    organismo.
  </>
);
const auricolotepiaDescription = (
  <>
    {auricolotepiaDescriptionSmall}
    <br></br>
    L'orecchio è un microsistema di punti corrispondenti all’intero sistema, che
    se stimolati adeguatamente, aiutano a ripristinare l'omeostasi corporea. La
    stimolazione utilizza un cercapunti, per trovare con precisione i punti da
    stimolare con i semi di vaccaria, che applicati con mini cerotti, possono
    essere mantenuti per ore o giorni e proseguono nel tempo l'effetto ottenuto
    durante la seduta.
  </>
);

const auricolotepia = new TreatmentObject(
  "Auricoloterapia",
  auricolotepiaDescription,
  auricoloterapiaImage,
  [],
  auricolotepiaDescriptionSmall
);
//MOXIBUSTIONE
const moxibustioneDescriptionSmall = (
  <>
    Bruciando l'artemisia si genera calore corporeo in profondità, effetto
    antinfiammatorio e antidolorifico, si dona una piacevole sensazione di
    rilassamento e serenità.
  </>
);
const moxibustioneDescription = (
  <>
    {moxibustioneDescriptionSmall}
    <br></br>È una tecnica della medicina cinese che consiste nell'applicazione
    prolungata, in determinati punti del corpo, di calore lungo i meridiani
    dell’agopuntura. La Moxa letteralmente significa "erba che brucia", il
    calore infatti, viene prodotto facendo bruciare coni o sigari di Artemisia
    Vulgaris a temperature tra i 500°C e 600°C ed ha rinomate proprietà
    terapeutiche. L'artemisia o erba di San Giovanni contiene infatti un olio
    naturale con proprietà antibatteriche ed antivirali ed attraverso la
    combustione si sprigiona e diffonde nel corpo e nell'ambiente. Il
    trattamento prevede la stimolazione dell'energia attraverso specifici punti
    con il sigaro mantenuto a poca distanza dalla pelle e lavorando sui
    meridiani, porta equilibrio fisico e psicologico. Il calore penetra
    piacevolmente in profondità, producendo un effetto antinfiammatorio ed
    antidolorifico, stimolando e riattivando il flusso energetico e dando una
    piacevole sensazione di relax e abbandono.
  </>
);

const moxibustione = new TreatmentObject(
  "Moxibustione",
  moxibustioneDescription,
  moxibustioneImage,
  [],
  moxibustioneDescriptionSmall
);
//GUASHA
const guashaDescriptionSmall = (
  <>
    È una tecnica della medicina orientale che utilizza cristalli di GIADA,
    QUARZO ROSA o AGATA, con movimenti lenti, delicati e controllati su punti
    specifici del viso, che aiuta a risvegliare, nella parte più yang del nostro
    corpo, l’energia vitale il nostro QI.
  </>
);
const guashaDescription = (
  <>
    {guashaDescriptionSmall}
    <br></br>I cristalli sono piatti con i bordi arrotondati, spesso a forma di
    cuore o paletta, ma anche in versione roll. Il massaggio Gua Sha stimola
    punti dei meridiani cinesi sul viso, aiutando equilibrio respiratorio
    (sinusiti, congestioni nasali, raffreddori…), digestivo ed intestinale
    (stitichezza, gonfiore addominale…), mentale (ansia, stress…). Questo
    massaggio facciale aumenta anche la microcircolazione sanguigna, migliorando
    il nutrimento della pelle (tono, luminosità...), favorisce il drenaggio dei
    liquidi che ristagnano (tossine, borse sotto gli occhi, gonfiori…) e
    migliora lo scioglimento delle tensioni generando rilassamento (rughe
    espressione…).
  </>
);

const guasha = new TreatmentObject(
  "Gua Sha Viso",
  guashaDescription,
  guashaImage,
  [],
  guashaDescriptionSmall
);
//TAPING ENERGETICO
const tapingDescriptionSmall = (
  <>
    È un bendaggio elastico adesivo che utilizza l'aspetto kinesiologico del
    taping tradizionale associandolo alla visione taoista della stimolazione dei
    meridiani in cui scorre il QI.
  </>
);
const tapingDescription = (
  <>
    {tapingDescriptionSmall}
    <br></br>
    La tecnica utilizza l'effetto meccanico del kinesiotaping classico, con la
    modalità energetica di stimolazione dei meridiani di medicina cinese.
    Tensione, direzionalità, lunghezza, colore e funzione sono i cardini della
    stimolazione. Il nastro posizionato nella modalità più funzionale al
    ripristino dell'energia, associato alla stimolazione dei punti di agopuntura
    rappresenta un valido sostegno agli altri trattamenti ed anche tra una
    seduta e l’altra.
  </>
);

const taping = new TreatmentObject(
  "Taping Energetico",
  tapingDescription,
  tapingImage,
  [],
  tapingDescriptionSmall
);

const description = (
  <>
    test Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum
    quia incidunt doloribus ullam est nisi, enim ex? Quam iure modi laboriosam
    illum hic, mollitia officiis. Deserunt praesentium rem asperiores ipsam?
  </>
);

const MTC = new Category(
  "Medicina Tradizionale Cinese",
  [],
  "/trattamenti/medicina-tradizionale-cinese",
  icon,
  MTCDescription
);
MTC.addElement(valutazione);
MTC.addElement(cromopuntura);
MTC.addElement(coppettazione);
MTC.addElement(auricolotepia);
MTC.addElement(moxibustione);
MTC.addElement(guasha);
MTC.addElement(taping);

export default MTC;
