import Category from "../Category";
import TreatmentObject from "../TreatmentObject";
import React from "react";

const tapingDescription = (
  <>
    È un bendaggio elastico ed adesivo che ha sia un effetto biomeccanico che
    energetico. La tecnica utilizza l’aspetto kinesiologico del taping
    tradizionale associandolo alla visione taoista della stimolazione dei
    meridiani in cui scorre il QI. Il nastro posizionato lungo la direzione
    dell’energia del meridiano ed associato alla stimolazione dei punti di
    agopuntura rappresenta un valido sostegno agli altri trattamenti ed anche
    tra una seduta e l’altra.
  </>
);
const TapingEnergetico = new Category(
  "Taping Energetico",
  [],
  "/trattamenti/taping-energetico",
  "",
  tapingDescription
);

export default TapingEnergetico;
