import { Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function NoMatch() {
  let location = useLocation();

  return (
    <div className="text-center">
      <h1 className="my-4">
        Siamo spiacenti ma la pagina cercata <code>{location.pathname}</code>{" "}
        non esiste
      </h1>
      <Image
        className="w-100"
        src={
          "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='40px' width='120px'><text x='50%' y='50%' dominant-baseline='middle'  alignment-baseline='central' text-anchor='middle' fill='rgba(93,93,91,0.5)' font-size='40' font-family='-apple-system, BlinkMacSystemFont, Arial' >404</text></svg>"
        }
        fluid
      />
      <h2 className="my-4 text-secondary">
        <Link to="/">Torna alla Home</Link>
      </h2>
    </div>
  );
}

export default NoMatch;
