import Category from "../Category";
import TreatmentObject from "../TreatmentObject";
import React from "react";
import icon from "../../images/treatmentsIcons/fisioterapia.png";

// RIABILITAZIONE MUSCOLOSCHELETRICA E POSTURALE

const FisioterapiaeRiabilitazioneDescription = (
  <>
    È l'agopuntura senza aghi che grazie ai principi della Medicina Tradizionale
    Cinese, utilizza la luce ed i colori al posto degli aghi per riequilibrare
    l'organismo nella sua globalità. Integrando il test kinesiologico,
    l'osservazione della lingua, l'ascolto dei polsi e l'anamnesi del paziente
    si trattano con la penna Cromomed i disturbi emersi per migliorare i sintomi
    ed arrivare alla causa del disagio.
  </>
);
/*
const FisioterapiaeRiabilitazione = new TreatmentObject(
  "Fisioterapia e Riabilitazione",
  FisioterapiaeRiabilitazioneDescription,
  icon
);
/*
//	RIABILITAZIONE NEUROLOGICA

const neurologicaDescription = (
  <>
    È l'agopuntura senza aghi che grazie ai principi della Medicina Tradizionale
    Cinese, utilizza la luce ed i colori al posto degli aghi per riequilibrare
    l'organismo nella sua globalità. Integrando il test kinesiologico,
    l'osservazione della lingua, l'ascolto dei polsi e l'anamnesi del paziente
    si trattano con la penna Cromomed i disturbi emersi per migliorare i sintomi
    ed arrivare alla causa del disagio.
  </>
);

const neurologica = new TreatmentObject(
  "Riabilitazione neurologica",
  neurologicaDescription
);

//	RIABILITAZIONE POST CHIRURGICA

const postChirurgicaDescription = (
  <>
    È l'agopuntura senza aghi che grazie ai principi della Medicina Tradizionale
    Cinese, utilizza la luce ed i colori al posto degli aghi per riequilibrare
    l'organismo nella sua globalità. Integrando il test kinesiologico,
    l'osservazione della lingua, l'ascolto dei polsi e l'anamnesi del paziente
    si trattano con la penna Cromomed i disturbi emersi per migliorare i sintomi
    ed arrivare alla causa del disagio.
  </>
);

const postChirurgica = new TreatmentObject(
  "Riabilitazione post chirurgica",
  postChirurgicaDescription
);

//	RIABILITAZIONE POST TRAUMATICA

const postTraumaticaDescription = (
  <>
    È l'agopuntura senza aghi che grazie ai principi della Medicina Tradizionale
    Cinese, utilizza la luce ed i colori al posto degli aghi per riequilibrare
    l'organismo nella sua globalità. Integrando il test kinesiologico,
    l'osservazione della lingua, l'ascolto dei polsi e l'anamnesi del paziente
    si trattano con la penna Cromomed i disturbi emersi per migliorare i sintomi
    ed arrivare alla causa del disagio.
  </>
);

const postTraumatica = new TreatmentObject(
  "Riabilitazione post traumatica",
  postTraumaticaDescription
);

//	RIABILITAZIONE SPORTIVA

const sportivaDescription = (
  <>
    È l'agopuntura senza aghi che grazie ai principi della Medicina Tradizionale
    Cinese, utilizza la luce ed i colori al posto degli aghi per riequilibrare
    l'organismo nella sua globalità. Integrando il test kinesiologico,
    l'osservazione della lingua, l'ascolto dei polsi e l'anamnesi del paziente
    si trattano con la penna Cromomed i disturbi emersi per migliorare i sintomi
    ed arrivare alla causa del disagio.
  </>
);

const sportiva = new TreatmentObject(
  "Riabilitazione sportiva",
  sportivaDescription
);

//	RIABILITAZIONE DELLA MANO

const manoDescription = (
  <>
    È l'agopuntura senza aghi che grazie ai principi della Medicina Tradizionale
    Cinese, utilizza la luce ed i colori al posto degli aghi per riequilibrare
    l'organismo nella sua globalità. Integrando il test kinesiologico,
    l'osservazione della lingua, l'ascolto dei polsi e l'anamnesi del paziente
    si trattano con la penna Cromomed i disturbi emersi per migliorare i sintomi
    ed arrivare alla causa del disagio.
  </>
);

const mano = new TreatmentObject("Riabilitazione della mano", manoDescription);
*/
const FisioterapiaeRiabilitazione = new Category(
  "Fisioterapia e Riabilitazione",
  [],
  "/trattamenti/terapie-tradizionali",
  icon,
  FisioterapiaeRiabilitazioneDescription
);
/*
FisioterapiaeRiabilitazione.addElement(Muscoloscheletrica);
FisioterapiaeRiabilitazione.addElement(neurologica);
FisioterapiaeRiabilitazione.addElement(postChirurgica);
FisioterapiaeRiabilitazione.addElement(postTraumatica);
FisioterapiaeRiabilitazione.addElement(sportiva);
FisioterapiaeRiabilitazione.addElement(mano);*/
//FisioterapiaeRiabilitazione.addElement(FisioterapiaeRiabilitazione);
export default FisioterapiaeRiabilitazione;
