import React from "react";
import {
  Navbar,
  Container,
  Nav,
  Offcanvas,
  NavDropdown,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  brands,
  solid,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import logo from "../images/loghi/logo.png";
import logoWhite from "../images/loghi/logo-white.png";

import {
  PantaReiInstagramLink,
  PantaReiLinkedinLink,
  PantaReiWhatsappLink,
  PantaReiMailLink,
  PantaReiFacebookLink,
} from "../Utils/links";

function CustomNavbar(props) {
  const [offcanvasShow, setOffcanvasShow] = React.useState(0);
  return (
    <Navbar
      bg={props.dark ? "myBlack" : "myWhite"}
      expand="lg"
      className={"mb-3 " + (props.dark ? "yin" : "yang")}
      sticky="top"
      variant={props.dark ? "dark" : "light"}
    >
      <Container fluid>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand"
          onClick={() => setOffcanvasShow(1)}
        />
        <Navbar.Brand
          as={Link}
          to="/"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar-expand"
        >
          <img
            src={props.dark ? logoWhite : logo}
            height={"50"}
            className="align-top"
            alt="Olistico Panta Rei"
          ></img>
        </Navbar.Brand>
        <Navbar.Offcanvas
          id={"offcanvasNavbar-expand"}
          aria-labelledby={"offcanvasNavbarLabel-expand"}
          show={offcanvasShow}
          className={props.dark ? "yin" : "yang"}
          onHide={() => setOffcanvasShow(0)}
        >
          <Offcanvas.Header
            closeButton
            closeVariant={props.dark ? "white" : undefined}
          >
            <Offcanvas.Title id={"offcanvasNavbarLabel-expand"}>
              <img
                src={props.dark ? logoWhite : logo}
                width={"100"}
                className="d-block d-lg-none mx-auto"
                alt="Logo centro olistico Panta Rei"
              ></img>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link
                as={Link}
                to="/"
                aria-controls="offcanvasNavbar-expand"
                onClick={() => setOffcanvasShow(0)}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/chi-sono"
                aria-controls="offcanvasNavbar-expand"
                onClick={() => setOffcanvasShow(0)}
              >
                Chi Sono
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/studio"
                onClick={() => setOffcanvasShow(0)}
              >
                Studio
              </Nav.Link>
              <NavDropdown
                title="Trattamenti"
                className={"dropdown " + (props.dark ? "yin" : "yang")}
                renderMenuOnMount={true}
              >
                <NavDropdown.Item
                  as={Link}
                  to="/trattamenti/medicina-tradizionale-cinese"
                  onClick={(event) => {
                    setOffcanvasShow(0);
                    event.target.blur();
                  }}
                >
                  Medicina tradizionale cinese
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/trattamenti/massaggi"
                  onClick={(event) => {
                    setOffcanvasShow(0);
                    event.target.blur();
                  }}
                >
                  Massaggi Energetico-Vibrazionali
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/trattamenti/riflessologia"
                  onClick={(event) => {
                    setOffcanvasShow(0);
                    event.target.blur();
                  }}
                >
                  Riflessologia ed Access Bars
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/trattamenti/tecniche-di-rilassamento"
                  onClick={(event) => {
                    setOffcanvasShow(0);
                    event.target.blur();
                  }}
                >
                  Relax, Zen e Stretching dei Meridiani
                </NavDropdown.Item>

                <NavDropdown.Item
                  as={Link}
                  to="/trattamenti/fitoterapia-e-aromaterapia"
                  onClick={(event) => {
                    setOffcanvasShow(0);
                    event.target.blur();
                  }}
                >
                  Floriterapia e aromaterapia
                </NavDropdown.Item>
                {/*}
                <NavDropdown.Item
                  as={Link}
                  to="/trattamenti/taping-energetico"
                  onClick={(event) => {
                    setOffcanvasShow(0);
                    event.target.blur();
                  }}
                >
                  Taping energetico
                </NavDropdown.Item>{*/}
                <NavDropdown.Item
                  as={Link}
                  to="/trattamenti/terapie-tradizionali"
                  onClick={(event) => {
                    setOffcanvasShow(0);
                    event.target.blur();
                  }}
                >
                  {" "}
                  Terapie tradizionali
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/trattamenti"
                  onClick={(event) => {
                    setOffcanvasShow(0);
                    event.target.blur();
                  }}
                >
                  Tutti i trattamenti
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                as={Link}
                to="/collaborazioni"
                onClick={() => setOffcanvasShow(0)}
              >
                Amici
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/contatti"
                onClick={() => setOffcanvasShow(0)}
              >
                Contatti
              </Nav.Link>
              <Nav.Link
                className="whatsapp-icon"
                href={PantaReiWhatsappLink}
                target="_blank"
              >
                <Row>
                  <Col xs={1} className="justify-content-center">
                    <FontAwesomeIcon size="xl" icon={brands("whatsapp")} />{" "}
                  </Col>
                  <Col className="d-lg-none">Whatsapp</Col>
                </Row>
              </Nav.Link>
              <Nav.Link
                className="mail-icon"
                href={PantaReiMailLink}
                target="_blank"
              >
                <Row>
                  <Col xs={1} className="justify-content-center">
                    <FontAwesomeIcon size="xl" icon={regular("envelope")} />
                  </Col>
                  <Col className="d-lg-none">Email</Col>
                </Row>
              </Nav.Link>
              <Nav.Link
                className="instagram-icon"
                href={PantaReiInstagramLink}
                target="_blank"
              >
                <Row>
                  <Col xs={1} className="justify-content-center">
                    <FontAwesomeIcon size="xl" icon={brands("instagram")} />
                  </Col>
                  <Col className="d-lg-none">Instagram</Col>
                </Row>
              </Nav.Link>
              {/*
              <Nav.Link
                className="facebook-icon d-lg-none"
                href={PantaReiFacebookLink}
                target="_blank"
              >
                <Row>
                  <Col xs={1} className="justify-content-center">
                    <FontAwesomeIcon size="xl" icon={brands("facebook")} />
                  </Col>
                  <Col className="d-lg-none">Facebook</Col>
                </Row>
              </Nav.Link>
              <Nav.Link
                className="linkedin-icon d-lg-none"
                href={PantaReiLinkedinLink}
                target="_blank"
              >
                <Row>
                  <Col xs={1} className="justify-content-center">
                    <FontAwesomeIcon size="xl" icon={brands("linkedin")} />
                  </Col>
                  <Col className="d-lg-none">Linkedin</Col>
                </Row>
              </Nav.Link>
              */}
              <Nav.Link
                onClick={() => {
                  props.changeTheme();
                }}
                target="_blank"
              >
                <Row>
                  <Col xs={1} className="justify-content-center">
                    <FontAwesomeIcon size="xl" icon={solid("yin-yang")} />
                  </Col>
                  <Col className="d-lg-none">
                    {props.dark ? "Tema Chiaro" : "Tema Scuro"}
                  </Col>
                </Row>
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
