import Category from "../Category";
import TreatmentObject from "../TreatmentObject";
import React from "react";

import icon from "../../images/treatmentsIcons/tutori.png";

const Kinesiostaping = new Category(
  "Kinesiostaping e Tutori termoplastici della mano",
  [],
  "/trattamenti/terapie-tradizionali",
  icon
);

export default Kinesiostaping;
