import Category from "../Category";
import TreatmentObject from "../TreatmentObject";
import React from "react";

import icon from "../../images/treatmentsIcons/tecniche-di-rilassamento-dark.png";
import relaxImage from "../../images/treatmentsImages/tecniche-di-rilassamento.jpg";
import zenImage from "../../images/treatmentsImages/TecnicheRilassamento/zen.jpg";
import yogaImage from "../../images/treatmentsImages/TecnicheRilassamento/yoga.jpg";
import relaxmudraImage from "../../images/treatmentsImages/TecnicheRilassamento/relaxMudra.jpg";
import gruppoImage from "../../images/treatmentsImages/TecnicheRilassamento/gruppo.jpg";
//ZEN PSICOSOMATICO E STRETCHING DEI MERIDIANI
const zenDescriptionSmall = (
  <>
    Lo Zen-Stretching® è una forma di ginnastica morbida basata sullo stiramento
    e la tonificazione delle catene muscolari lungo il percorso dei meridiani
    energetici.
  </>
);
const zenDescription = (
  <>
    {zenDescriptionSmall} <br></br>Ogni esercizio è studiato per sollecitare in
    modo diverso, a seconda delle necessità individuali, il flusso di energia
    vitale che percorre il corpo, ogni singolo meridiano o coppia “yin-yang” di
    meridiani. La scelta degli esercizi più adatta è veicolata da un
    facilitatore che conduce un trattamento personalizzato ed in linea con le
    capacità e le esigenze di ciascuno.
  </>
);

const zen = new TreatmentObject(
  "Zen Psicosomatico e Stretching dei Meridiani",
  zenDescription,
  zenImage,
  [],
  zenDescriptionSmall
);
//ACTIVE YOGA POSITION E MES
const yogaDescriptionSmall = (
  <>
    Il massaggio energetico dei chakra qui di arricchisce di una stimolazione
    attiva guidata delle posizioni di yoga relative ad ogni centro energetico.
  </>
);
const yogaDescription = (
  <>
    {yogaDescriptionSmall} <br></br>La valutazione dell’equilibrio dei chakra ci
    permette di sapere quale fra tutti non sia in armonia e di scegliere le
    posizioni yoga più adatte al suo centro. Il MES è la ciliegina sulla torta
    dopo una sessione di yoga, la stimolazione attiva ci permette, durante il
    massaggio e l’ascolto dei mantra, di essere ancora più funzionali al nostro
    riassetto energetico.
  </>
);

const yoga = new TreatmentObject(
  "Active Yoga Position e MES",
  yogaDescription,
  yogaImage,
  [],
  yogaDescriptionSmall
);
//RELAX+MUDRA
const relaxMudraDescriptionSmall = (
  <>
    Esercizi specifici per ognuno di noi, da ricevere passivamente o con facili
    esercizi attivi, dalle posizioni yoga, alla meditazione associata ai MUDRA,
    dalla respirazione energetica, al training autogeno, facilitano la gestione
    dello stress, dell’ansia, della rabbia, dell'insonnia.
  </>
);
const relaxMudraDescription = (
  <>
    {relaxMudraDescriptionSmall} <br></br>Sono metodi diversi e specifici per
    ogni individuo e per ogni disagio. Ognuno di noi ha necessità, bisogni e
    modi di rilassarsi diversi, l'obiettivo è scoprire insieme quali sono i più
    adatti a ciascuno ed integrarli nella tua routine quotidiana. Alcuni di noi
    si rilassano e si abbandonano di più sdraiati a terra, su un comodo
    tappetino, tra candele e musica relax, sentendo le pressioni delle mani
    dell’operatore che guidano respirazione, ascolto ed abbandono. Qualcuno
    preferisce fare semplici esercizi di yoga con la musica per sentirsi più
    centrato, chi invece vuole concentrarsi sulla respirazione o sulle tecniche
    di visualizzazione per canalizzare ansia e stress della vita di tutti i
    giorni ed ancora chi preferisce meditare e concentrarsi sui mudra più utili
    alle emozioni di quel momento.
  </>
);

const relaxMudra = new TreatmentObject(
  "Tecniche di Rilassamento e Mudra",
  relaxMudraDescription,
  relaxmudraImage,
  [],
  relaxMudraDescriptionSmall
);
//LEZIONI GRUPPO
const gruppoDescriptionSmall = (
  <>
    Lezioni di respirazione energetica, di pratica meditativa, di
    visualizzazione e rilassamento con cristalli e Mudra, sia in coppia che in
    piccoli gruppi da 5-6 persone al massimo.
  </>
);
const gruppoDescription = (
  <>
    {gruppoDescriptionSmall} <br></br>Le tecniche di rilassamento si plasmano in
    base alle esigenze della coppia o del gruppo per il nostro benessere a 360°,
    per aiutarci ad avere qualche strumento in più per vivere meglio nella vita
    di ogni giorno.
  </>
);

const gruppo = new TreatmentObject(
  "Lezioni in coppia e di gruppo",
  gruppoDescription,
  gruppoImage,
  [],
  gruppoDescriptionSmall
);
//TECNICHE DI RILASSAMENTO GENERICHE
const tecnicheDiRilassamentoDescriptionSmall = (
  <>
    Esercizi specifici per ognuno di noi, da ricevere passivamente, dalle
    posizioni yoga per stimolare un chakra, alla meditazione, alle respirazioni,
    al training autogeno, facilitano la gestione dello stress, dell’ansia, della
    rabbia, dell'insonnia.
  </>
);
const tecnicheDiRilassamentoDescription = (
  <>
    {tecnicheDiRilassamentoDescriptionSmall}
    <br></br>
    Sono metodi diversi e specifici per ogni individuo e per ogni disagio.
    Ognuno di noi ha necessità, bisogni, e modi di rilassarsi diversi,
    l'obiettivo è scoprire insieme quali sono i più adatti a ciascuno ed
    integrarli nella tua routine quotidiana. Alcuni di noi si rilassano e si
    abbandonano di più sdraiati a terra, su un comodo tappetino, tra candele e
    musica relax, sentendo le pressioni delle mani dell’operatore che eseguono
    mobilizzazioni e pressioni. Altri preferiscono fare semplici esercizi di
    yoga che stimolano un chakra specifico, così come posizioni di meditazione
    che associate alla respirazione che facilitano la gestione dello stress,
    dell'ansia, della rabbia, dell’insonnia… oppure esercizi di visualizzazione
    e training autogeno guidato, utili nella gestione delle difficoltà della
    vita di tutti i giorni.
  </>
);

/*const tecnicheDiRilassamento = new TreatmentObject(
  "Relax, Zen e Stretching dei Meridiani",
  tecnicheDiRilassamentoDescription,
  relaxImage,
  [],
  tecnicheDiRilassamentoDescriptionSmall
);*/
//ESERCIZI ASSOCIATI AI CHAKRA

/*const chakraDescription = (
  <>
    È l'agopuntura senza aghi che grazie ai principi della Medicina Tradizionale
    Cinese, utilizza la luce ed i colori al posto degli aghi per riequilibrare
    l'organismo nella sua globalità. Integrando il test kinesiologico,
    l'osservazione della lingua, l'ascolto dei polsi e l'anamnesi del paziente
    si trattano con la penna Cromomed i disturbi emersi per migliorare i sintomi
    ed arrivare alla causa del disagio.
  </>
);

const chakra = new TreatmentObject(
  "Esercizi associati ai Chakra",
  chakraDescription
);*/

//RESPIRAZIONI E MEDITAZIONI GUIDATE

/*const meditazioneDescription = (
  <>
    È l'agopuntura senza aghi che grazie ai principi della Medicina Tradizionale
    Cinese, utilizza la luce ed i colori al posto degli aghi per riequilibrare
    l'organismo nella sua globalità. Integrando il test kinesiologico,
    l'osservazione della lingua, l'ascolto dei polsi e l'anamnesi del paziente
    si trattano con la penna Cromomed i disturbi emersi per migliorare i sintomi
    ed arrivare alla causa del disagio.
  </>
);

const meditazione = new TreatmentObject(
  "Respirazioni e Meditazioni guidate",
  meditazioneDescription
);*/

//ESERCIZI DI VISUALIZZAZIONI E TRAINING AUTOGENO

/*const trainingAutogenoDescription = (
  <>
    È l'agopuntura senza aghi che grazie ai principi della Medicina Tradizionale
    Cinese, utilizza la luce ed i colori al posto degli aghi per riequilibrare
    l'organismo nella sua globalità. Integrando il test kinesiologico,
    l'osservazione della lingua, l'ascolto dei polsi e l'anamnesi del paziente
    si trattano con la penna Cromomed i disturbi emersi per migliorare i sintomi
    ed arrivare alla causa del disagio.
  </>
);

const trainingAutogeno = new TreatmentObject(
  "Esercizi di visualizzazione e Training autogeno",
  trainingAutogenoDescription
);*/

const TecnicheDiRilassamento = new Category(
  "Relax, Zen e Stretching dei Meridiani",
  [],
  "/trattamenti/tecniche-di-rilassamento",
  icon
);
TecnicheDiRilassamento.addElement(zen);
TecnicheDiRilassamento.addElement(yoga);
TecnicheDiRilassamento.addElement(relaxMudra);
TecnicheDiRilassamento.addElement(gruppo);
//TecnicheDiRilassamento.addElement(tecnicheDiRilassamento);

export default TecnicheDiRilassamento;
