import React, { useState } from "react";
import { Image, Row, Col } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  brands,
  solid,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro";

import buttonIcon from "../images/contact-icon.png";
import buttonIconDark from "../images/contact-icon-dark.png";
import {
  PantaReiWhatsappLink,
  PantaReiInstagramLink,
  PantaReiLinkedinLink,
  PantaReiMailLink,
  PantaReiFacebookLink,
} from "../Utils/links";

function ContactsCircle({ dark = false }) {
  const [open, setOpen] = useState(false);
  //gestore showMore/showLess
  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <div id="main-div">
      <div id="main-button" className={open ? "open" : ""} onClick={handleOpen}>
        {open ? (
          <FontAwesomeIcon size="xl" icon={solid("times")} />
        ) : (
          <Image
            src={dark ? buttonIconDark : buttonIcon}
            fluid
            roundedCircle
          ></Image>
        )}
      </div>
      <a href={PantaReiWhatsappLink} target="_blank" className="whatsapp-icon">
        <FontAwesomeIcon
          size="xl"
          icon={brands("whatsapp")}
          onClick={() => setOpen(false)}
        />
      </a>
      <a href={PantaReiMailLink} target="_blank" className="mail-icon">
        <FontAwesomeIcon
          size="xl"
          icon={regular("envelope")}
          onClick={() => setOpen(false)}
        />
      </a>
      <a
        href={PantaReiInstagramLink}
        target="_blank"
        className="instagram-icon"
      >
        <FontAwesomeIcon
          size="xl"
          icon={brands("instagram")}
          onClick={() => setOpen(false)}
        />
      </a>
      <a href={PantaReiFacebookLink} target="_blank" className="facebook-icon">
        <FontAwesomeIcon
          size="xl"
          icon={brands("facebook-square")}
          onClick={() => setOpen(false)}
        />
      </a>
      <a href={PantaReiLinkedinLink} target="_blank" className="linkedin-icon">
        <FontAwesomeIcon
          size="xl"
          icon={brands("linkedin")}
          onClick={() => setOpen(false)}
        />
      </a>
    </div>
  );
}

export default ContactsCircle;
