import React from "react";
import { Row } from "react-bootstrap";
import CategoryComponent from "../../components/CategoryComponent";
import Data from "../../Data/Categories/Riflessologia";
import { Helmet } from "react-helmet-async";
function Riflessologia({ footer = true }) {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Riflessologia ed Access Bars - Studio Olistico Panta Rei</title>
        <meta
          name="description"
          content="Esplora i trattamenti di riflessologia e Access Bars presso lo Studio Olistico Panta Rei. Scopri come Access Bars, con i suoi 32 punti sulla testa, stimoli un cambiamento positivo nel cervello, destrutturando stress, pensieri ed emozioni. Approfondisci la Riflessologia facciale Dien Chan, una tecnica vietnamita che utilizza mappe riflesse del viso per un autotrattamento quotidiano. Sperimenta la Riflessologia della mano, del piede e MES, massaggiando punti riflessi per riequilibrare organi e emozioni. Goditi la Riflessologia Testa-Viso-Mani-Piedi, un trattamento globale per potenziare gli stimoli e gli effetti su tutte le parti del corpo."
        />
        <meta
          name="keywords"
          content="Riflessologia, Access Bars, Dien Chan, Riflessologia della Mano, Riflessologia del Piede, MES, Riflessologia Testa Viso Mani Piedi, Studio Olistico, Panta Rei, Mtc, Kinesiologia, Coppettazione, moxa, Moxibustione, Taping, Riflessologia e Dien Chan, Chakra, Fiori Bach e Australiani, Meditazione, Medicina Tradizionale Cinese, Massaggi, Cassano d'Adda, Martesana, Milano,  Terapie Olistiche, Cassano"
        />
        <meta
          property="og:title"
          content="Riflessologia ed Access Bars - Studio Olistico Panta Rei"
        />
        <meta
          property="og:description"
          content="Scopri i benefici di Riflessologia e Access Bars presso lo Studio Olistico Panta Rei a Cassano D'Adda. Dai BARS® che stimolano il cambiamento positivo nel cervello alla Riflessologia Facciale Dien Chan, alle tecniche sulla mano e piede, esplora trattamenti che favoriscono il rilascio di blocchi fisici e mentali."
          data-rh="true"
        />
      </Helmet>
      <CategoryComponent data={Data} footer={footer} />
    </>
  );
}

export default Riflessologia;
