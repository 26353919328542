import React from "react";
import { Col, Row, Image, Container } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";
import Category from "../../Data/Category";
import { Helmet } from "react-helmet-async";
import Data from "../../Data/Data";

import olisticodark from "../../images/treatmentsIcons/olistico-dark.png";
import olisticolight from "../../images/treatmentsIcons/olistico-light.png";
import tradizionaledark from "../../images/treatmentsIcons/tradizionale-dark.png";
import tradizionalelight from "../../images/treatmentsIcons/tradizionale-light.png";

import "../../homepage.scss";

function HomepageDesktop({ dark }) {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Studio Olistico Panta Rei</title>
        <meta
          name="description"
          content="Scopri il benessere personale con i trattamenti della Medicina Tradizionale Cinese e i massaggi esclusivi offerti da Nicole, l'esperta dello Studio Olistico Panta Rei. Dalle tecniche di cromopuntura e coppettazione cinese all'auricoloterapia, moxibustione e gua sha viso, fino ai massaggi come Aroma Massage, Massaggio Bioenergetico e Thai Massage, Nicole personalizza percorsi di guarigione per armonizzare il tuo corpo, mente e spirito. Sperimenta l'arte millenaria della medicina tradizionale cinese e lasciati trasportare in uno stato di relax profondo. Prenota oggi per iniziare il tuo viaggio verso il benessere olistico."
        />
        <meta
          name="keywords"
          content="Panta Rei, Studio Olistico, Mtc, Kinesiologia, Coppettazione, moxa, Moxibustione, Taping, Riflessologia e Dien Chan, Access Bars, Chakra, Fiori Bach e Australiani, Meditazione, Medicina Tradizionale Cinese, Massaggi, Cassano d'Adda, Martesana, Milano,  Terapie Olistiche, Cassano, Cromopuntura, Coppettazione Cinese, Auricoloterapia, Moxibustione, Gua Sha Viso, Aroma Massage, Massaggio Bioenergetico, Thai Massage"
        />

        <meta property="og:title" content="Studio olistico Panta Rei" />
        <meta
          property="og:description"
          content="Scopri il benessere attraverso la Medicina Olistica nel nostro studio a Cassano D'Adda. 
          Offriamo trattamenti di Medicina Tradizionale Cinese come Massaggi Energetico-Vibrazionali, Riflessologia, Access Bars, Relax e Stretching dei Meridiani, Floriterapia, Fisioterapia e molto altro. 
          Prenditi spazio per te e goditi la cura olistica presso lo Studio Panta Rei."
          data-rh="true"
        />
      </Helmet>
      <div className=" mx-0 px-0 justify-content-center align-items-center">
        <Row className="home-desktop d-flex flex-column h-100 justify-content-center align-items-center snap-element">
          <Row className="justify-content-center align-items-center ">
            <blockquote className=" blockquote text-center justify-content-center  align-items-center">
              <p className="">
                <cite>
                  "Conoscere te stesso è il principio di tutta la saggezza."
                </cite>
              </p>
              <footer className="blockquote-footer">Aristotele</footer>
            </blockquote>
          </Row>
          <Row className="flex-grow-1  justify-content-center  align-items-center">
            <Col lg={6} className="main-img h-100 "></Col>
          </Row>
          <Row className=" justify-content-center  align-items-center">
            <blockquote className="blockquote text-center  justify-content-center  align-items-center">
              <p className="">
                <cite className="">
                  "Colui che conosce tutte le risposte, non ha fatto tutte le
                  domande."
                </cite>
              </p>
              <footer className="blockquote-footer">Confucio</footer>
            </blockquote>
          </Row>
        </Row>
        <Container className="h-100 d-flex flex-column ref snap-element">
          <Row className="justify-content-center mb-4">
            <Col
              xs={4}
              className="p-2 text-center  justify-content-center  align-items-center"
            >
              <Image src={dark ? olisticodark : olisticolight} fluid></Image>
              <h2>Medicina Olistica</h2>
            </Col>
            <Col xs={2} />
            <Col
              xs={4}
              className="p-2 text-center  justify-content-center  align-items-center"
            >
              <Image
                src={dark ? tradizionaledark : tradizionalelight}
                fluid
              ></Image>
              <h2>Medicina Tradizionale</h2>
            </Col>
          </Row>
          <Row className="h-100  justify-content-center  align-items-center">
            <Col xs={4} className="h-100 flex-grow-1">
              {Data["yin"].map((category, catKey) => {
                return (
                  <Row
                    key={catKey}
                    as={Link}
                    to={category.link}
                    className="service-container"
                  >
                    <Col
                      xs={4}
                      className="my-2 justify-content-center d-flex flex-column"
                    >
                      <Image
                        src={category.icon}
                        roundedCircle
                        fluid
                        className="w-100"
                      ></Image>
                    </Col>
                    <Col className="py-2 justify-content-center d-flex flex-column p-2">
                      <h3 className="text-center p-0 m-0">{category.name}</h3>
                    </Col>
                  </Row>
                );
              })}
            </Col>
            <Col xs={2}></Col>
            <Col xs={4} className="h-100 flex-grow-1">
              {Data["yang"].map((category, catKey) => {
                return (
                  <Row
                    key={catKey}
                    as={Link}
                    to={category.link}
                    className="service-container"
                  >
                    <Col
                      xs={4}
                      className="my-2 justify-content-center d-flex flex-column"
                    >
                      <Image
                        src={category.icon}
                        roundedCircle
                        fluid
                        className="w-100"
                      ></Image>
                    </Col>
                    <Col className="py-2 justify-content-center d-flex flex-column p-2">
                      <h3 className="text-center p-0 m-0">{category.name}</h3>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
          <blockquote className="mt-5 blockquote text-center w-100">
            <h3>
              <cite>
                Sei stanco, stressato, senza energie e non trovi mai tempo per
                il tuo benessere?
                <br />
                Prenditi spazio per te!
                <br />
                Panta Rei è il luogo ideale per lasciarti coccolare.
              </cite>
            </h3>
            <footer className="w-100">
              <cite>Ti aspetto, Nicole</cite>
            </footer>
          </blockquote>
        </Container>
      </div>
    </>
  );
}

export default HomepageDesktop;
