import React, { useState } from "react";
import { Col, Row, Image } from "react-bootstrap";

function Treatment({ descriptionSmall, descriptionBig, title, image }) {
  //stato per gestire short or long text
  const [showMore, setShowMore] = useState(false);
  //gestore showMore/showLess
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Row className="service-container m-0 p-0 mt-3" id={title}>
      <Col className="justify-content-center d-flex d-md-none flex-column p-2 p-md-5">
        <h2 className="mt-3 text-center text-md-start">{title}</h2>
      </Col>
      <Col
        md={5}
        lg={4}
        className="justify-content-center d-flex flex-column p-2 px-5 p-md-5"
      >
        <Image src={image} rounded fluid className=""></Image>
      </Col>
      <Col className="justify-content-center text-center d-flex d-none d-md-flex flex-column p-2 p-md-5">
        <h2 className="mt-3 text-center text-md-start">{title}</h2>
        <p className="text-center text-md-start">{descriptionBig}</p>
      </Col>
      <Col className="justify-content-center align-items-center text-center d-flex d-md-none flex-column p-2 p-md-5">
        <p className="justify-content-center align-items-center text-center d-flex d-flex d-sm-none flex-column p-2 p-md-5">
          {showMore ? descriptionBig : descriptionSmall}
          <p
            className={"my-3 mx-auto d-block d-sm-none"}
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleShowMore}
          >
            {showMore ? "Mostra di meno" : "Mostra di più"}
          </p>
        </p>
      </Col>
    </Row>
  );
}

export default Treatment;
